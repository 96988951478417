<template>
  <div>
    <b-loading :is-full-page="true" v-model="loading"></b-loading>
    <div class="p:24" v-if="service">
      <label class="--label mb:32 flex:|center gap:8">
        <input type="checkbox" class="--checkbox" v-model="form.fields.sendConfirmationEmailCustomer" />
        Send confirmation email to customer
      </label>
      <label class="--label mb:32 flex:|center gap:8">
        <input type="checkbox" class="--checkbox" v-model="form.fields.sendConfirmationEmailStaff" />
        Send confirmation email to staff
      </label>
      <div class="field">
        <b-field label="Email content">
          <MarkdownEditor v-model="form.fields.emailContent" />
        </b-field>
        <FormError field="emailContent" :form="form" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FormMenu from '@/views/Admin/Service/FormMenu'
import Paginator from '@/views/Admin/components/Paginator'
import MarkdownEditor from '@/components/admin/MarkdownEditor.vue'

import { Form } from '@/internal/modules'
import { Service } from '@/internal'

export default {
  name: 'Email',
  components: {
    FormMenu,
    Paginator,
    MarkdownEditor,
  },
  computed: {
    service() {
      return this.serviceId ? this.$store.getters['service/show'](this.serviceId) : null
    },
    timezone() {
      return this.$store.state.system.timezone
    },
  },
  mounted() {
    this.loadService()
  },
  data() {
    return {
      paginator: null,
      loading: false,
      serviceId: null,
      form: new Form({
        email_content: '',
      }),
    }
  },
  methods: {
    ...mapActions({
      serviceShow: 'service/show',
      serviceUpdate: 'service/update',
    }),
    loadService() {
      this.loading = true

      return this.serviceShow({
        id: this.$route.query.show,
      })
        .then((response) => {
          this.serviceId = response[0]
          this.form.fields = {
            ...this.service,
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSave() {
      this.loading = true
      this.form.errors.clear()

      const fields = {
        ...this.form.fields,
      }

      let action = null

      if (this.service) {
        action = this.serviceUpdate
      } else {
        return
      }

      action(Service.preparePayload(fields))
        .then((response) => {
          window.app.snackbar({
            message: 'Saved successfully!',
          })
        })
        .catch((error) => {
          this.form.recordErrors(error)
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss"></style>
