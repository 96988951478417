<template>
  <b-modal v-model="isOpen" trap-focus :destroy-on-hide="true" :can-cancel="false">
    <div class="bg:white mx:auto r:12 w:100%" :class="_class">
      <div class="flex:|center p:24 gap:24">
        <button class="reset size:24" @click="close">
          <Icon admin name="x" />
        </button>
        <div slot="title" class="f:24 semi">{{ title }}</div>
      </div>
      <div class="p:24 max-h:60vh hide-scroll" :class="{ 'ofy:scroll': scrollableContent }">
        <slot></slot>
      </div>
      <div class="p:16 flex:end gap:12 bt:base-20 mt:16" slot="footer">
        <button class="reset flex:|center gap:8 f:16 semi p:10|12 r:8 o:0.9:hover" @click="close">
          <Icon admin name="x" />
          Cancel
        </button>
        <button
          class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent bg:accent-10:disabled p:10|12 r:8 o:0.9:hover"
          @click="$emit('submit')"
          :disabled="disabledSubmit"
        >
          <Icon admin name="check" />
          {{ submitText }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    _class: {
      type: String,
      default: 'w:640@md',
    },
    scrollableContent: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: 'Save',
    },
    disabledSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    close() {
      this.isOpen = false
      this.$emit('input', false)
      this.$emit('close')
    },
  },
  watch: {
    value(newValue) {
      this.isOpen = newValue
    },
    isOpen(newValue) {
      this.$emit('input', newValue)
    },
  },
}
</script>

<style>
.modal-close {
  display: none !important;
}

.modal-content {
  overflow: visible !important;
}

</style>
