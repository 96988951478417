<template>
  <SideDrawer _class="w:640@lg" :loading="loading" @close="$emit('close')">
    <template slot="header">
      <div class="ml:24 f:20 semi">{{ title }}</div>
    </template>
    <div class="p:20">
      <div class="mb:16">
        <div class="columns">
          <div class="column is-half">
            <div class="section-title">
              <Icon admin name="spark" />
              Service
            </div>
            <SelectInput
              v-model="selectedServiceId"
              :options="serviceOptions"
              :disabled="isNonScheduledBooking"
              placeholder="Select service"
              @input="handleServiceSelect()"
            />
          </div>
        </div>
      </div>
      <template v-if="service">
        <div class="columns">
          <div class="column">
            <div
              class="modal-label is-flex is-align-items-center has-text-weight-semibold mb-2 is-justify-content-space-between"
            >
              <div class="is-flex is-align-items-center">
                <img src="/images/admin/customer.svg" class="mr-2" />
                Customer
              </div>
              <div>
                <template v-if="selectedCustomer">
                  <a v-if="!isNonScheduledBooking" @click="handleClearCustomer" class="is-pulled-right">
                    <i class="fas fa-times"></i>
                    Clear
                  </a>
                </template>
                <template v-else>
                  <button
                    @click="$refs.createCustomerDialog.open = true"
                    class="reset flex:|center gap:8 f:16 semi c:accent o:0.9:hover"
                  >
                    <Icon admin name="plus-circle" />
                    Add new
                  </button>
                </template>
              </div>
            </div>
            <div class="field">
              <b-field v-if="!selectedCustomer">
                <b-autocomplete
                  v-model="search"
                  placeholder="Search by id, email or phone number"
                  :data="filteredCustomers"
                  class="new-input"
                  field="label"
                  @select="handleCustomerSelect"
                  @input="handleCustomerSearch"
                ></b-autocomplete>
              </b-field>
            </div>
            <div class="field">
              <template v-if="selectedCustomer">
                {{ selectedCustomer.name }}
                <br />
                {{ selectedCustomer.email }}
                <br />
                {{ selectedCustomer.phoneE164 }}
                <br />
                <template v-if="activeBooking">
                  <hr class="mt-1 mb-1" />
                  Booking #{{ activeBooking.number }}
                  <br />
                  {{ activeBooking.startAt.format('D MMM YY') }} - {{ activeBooking.endAt.format('D MMM YY') }} ({{
                    activeBooking.nightsCount
                  }}
                  nights)
                  <br />
                  <template v-if="activeBooking.category">
                    {{ activeBooking.category.name }}
                    <br />
                  </template>
                  {{ activeBooking.totalPersons }} guests
                  <br />
                </template>
              </template>
              <FormError field="customer_id" :form="form" />
            </div>
          </div>
          <div class="column">
            <div class="modal-label is-flex is-align-items-center has-text-weight-semibold mb-2">
              <img src="/images/users2-1.svg" class="mr-2" />
              Guests
            </div>
            <GroupInput
              class="new-input"
              @input="handleGroupChange"
              :show-icon="false"
              :full-width="false"
              v-model="form.fields.adults_number"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="modal-label is-flex is-align-items-center has-text-weight-semibold mb-2">
              <img src="/images/calendar2.svg" class="mr-2" />
              Date
            </div>
            <CalendarInput v-model="datepicker" @input="handleDateChange" :allow-clear="false" />
          </div>
          <div class="column">
            <div class="modal-label is-flex is-align-items-center has-text-weight-semibold mb-2">
              <img src="/images/clock2.svg" class="mr-2" />
              Time
            </div>
            <TimeslotInput ref="timeslotInput" v-model="form.fields.from" :timeslots="timeslots" allow-custom-time />
          </div>
        </div>
        <div class="columns" v-if="service.subtype === 'restaurant'">
          <div class="column">
            <SpaceItemSelect v-model="form.fields.service_item_id" :query="spaceItemQuery" :disabled="true" />
          </div>
        </div>
        <!-- Restaurant -->
        <template v-if="service.activityType === 'restaurant'">
          <div class="columns">
            <div class="column">
              <div class="modal-label is-flex is-align-items-center has-text-weight-semibold mb-2">
                <img src="/images/admin/note.svg" class="mr-2" />
                Package
              </div>
              <SelectInput v-model="form.fields.package" :options="packageOptions" placeholder="N/A"></SelectInput>
              <FormError field="package" :form="form" />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="modal-label is-flex is-align-items-center has-text-weight-semibold mb-2">
                <img src="/images/admin/note.svg" class="mr-2" />
                Dietary requirements
              </div>
              <textarea class="--textarea" v-model="form.fields.dietary_requirements" />
              <FormError field="dietary_requirements" :form="form" />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="modal-label is-flex is-align-items-center has-text-weight-semibold mb-2">
                <img src="/images/admin/note.svg" class="mr-2" />
                Special occasion
              </div>
              <textarea class="--textarea" v-model="form.fields.special_occasion" />
              <FormError field="special_occasion" :form="form" />
            </div>
          </div>
        </template>
        <!-- Spa -->
        <template v-if="service.activityType === 'spa'">
          <div class="columns">
            <div class="column">
              <div class="modal-label is-flex is-align-items-center has-text-weight-semibold mb-2">
                <img src="/images/admin/note.svg" class="mr-2" />
                Form status
              </div>
              <SelectInput
                v-model="form.fields.form_status"
                :options="formStatusOptions"
                placeholder="Form status"
              ></SelectInput>
              <FormError field="form_status" :form="form" />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="modal-label is-flex is-align-items-center has-text-weight-semibold mb-2">
                <img src="/images/admin/note.svg" class="mr-2" />
                Shoe sizes
              </div>
              <textarea class="--textarea" rows="3" v-model="form.fields.shoe_sizes" />
              <FormError field="shoe_sizes" :form="form" />
            </div>
          </div>
        </template>
        <!-- Notes -->
        <div class="columns">
          <div class="column">
            <div class="modal-label is-flex is-align-items-center has-text-weight-semibold mb-2">
              <img src="/images/admin/note.svg" class="mr-2" />
              Notes
            </div>
            <textarea class="--textarea" rows="8" v-model="form.fields.note" />
            <FormError field="note" :form="form" />
          </div>
        </div>
        <!-- Error -->
        <b-notification v-if="error" ref="error" type="is-danger" aria-close-label="Hide error" role="alert">
          {{ error }}
        </b-notification>
      </template>
    </div>
    <template slot="footer" class="modal-footer p-5 has-text-right">
      <b-button class="button button--ghost rounded-8 mr-2" @click="$emit('close')">
        <div class="is-flex is-align-items-center has-text-weight-semibold">
          <img src="/images/close2-black.svg" class="mr-2" style="height: 18px" />
          Cancel
        </div>
      </b-button>
      <template v-if="service">
        <!--  Free or payment on spot -->
        <template v-if="service.bookFinalStep === 'confirmation' || isNonScheduledBooking">
          <b-button :disabled="!isFormReady" class="button button--primary rounded-8" @click="handleConfirm">
            <div class="is-flex is-align-items-center has-text-weight-bold font--primary" style="font-size: 18px">
              <img src="/images/check2-black.svg" class="mr-2" style="height: 26px" />
              Book
            </div>
          </b-button>
        </template>
        <!--  Upfront payment -->
        <template v-else>
          <!--  If customer has active booking (accommodation) -->
          <b-button
            v-if="activeBooking"
            :disabled="!isFormReady"
            class="button button--primary rounded-8"
            @click="handleAddToBill"
          >
            <div class="is-flex is-align-items-center has-text-weight-bold font--primary" style="font-size: 18px">
              <img src="/images/check2-black.svg" class="mr-2" style="height: 26px" />
              Add to bill
            </div>
          </b-button>
          <b-button :disabled="!isFormReady" class="button button--primary rounded-8" @click="handlePay">
            <div class="is-flex is-align-items-center has-text-weight-bold font--primary" style="font-size: 18px">
              <img src="/images/check2-black.svg" class="mr-2" style="height: 26px" />
              Pay
            </div>
          </b-button>
        </template>
      </template>
    </template>
    <CreateCustomerDialog ref="createCustomerDialog" @onSave="handleNewCustomer" />
    <BookingPaymentDialog ref="bookingPaymentDialog" @success="handleSuccess" />
  </SideDrawer>
</template>

<script>
import mixin from '@/mixin'
import { Form } from '@/internal/modules'
import { Booking } from '@/internal'
import CalendarInput from '@/components/admin/CalendarInput'
import GroupInput from '@/components/admin/GroupInput'
import moment from 'moment'
import { mapActions } from 'vuex'
import { debounce } from 'lodash'
import SideDrawer from '@/components/admin/SideDrawer.vue'
import CreateCustomerDialog from '@/components/admin/Customer/CreateCustomerDialog'
import TimeslotInput from '@/components/admin/TimeslotInput'
import SpaceItemSelect from '@/components/admin/SpaceItemSelect'
import BookingPaymentDialog from '@/components/admin/Booking/BookingPaymentDialog.vue'

export default {
  name: 'BookingCreateModal',
  mixins: [mixin],
  components: {
    GroupInput,
    CalendarInput,
    CreateCustomerDialog,
    TimeslotInput,
    SpaceItemSelect,
    BookingPaymentDialog,
    SideDrawer,
  },
  props: {
    date: {
      type: Date || null,
      required: false,
    },
    nonScheduledBookingId: {
      type: String || null,
      required: false,
    },
    serviceId: {
      type: String || null,
      required: false,
    },
    customerId : {
      type: String || null,
      required: false
    }
  },
  computed: {
    title() {
      return this.nonScheduledBookingId ? 'Schedule Booking' : 'New Booking'
    },
    activeBooking() {
      return this.selectedCustomer?.activeBookingId
        ? this.$store.getters['booking/show'](this.selectedCustomer.activeBookingId)
        : null
    },
    newCustomer() {
      return this.newCustomerId ? this.$store.getters['customer/findBy'](this.newCustomerId, 'id') : null
    },
    booking() {
      return {
        date: this.form.fields.date ? this.dateFormat(this.form.fields.date) : '',
        time: this.form.fields.from ? this.timeFormatTimezone(this.form.fields.from).format('HH:mm') : '',
        price: this.totalPrice,
        quantity: this.form.fields.quantity,
        group: this.groupFormat(this.form.fields.adults_number, this.form.fields.children_number),
        duration: this.timeFormat(this.form.fields.quantity * this.service.duration),
      }
    },
    service() {
      return this.selectedServiceId ? this.$store.getters['service/show'](this.selectedServiceId) : null;
    },
    spaceItemQuery() {
      return {
        serviceId: this.service.id,
        quantity: this.form.fields.quantity,
        date: this.form.fields.date,
        time: this.form.fields.from,
        persons: this.form.fields.adults_number + this.form.fields.children_number,
      }
    },
    filteredCustomers() {
      return this.searchedCustomers.map((customer) => {
        let label = customer.attributes.name

        if (customer.attributes.origin_name) {
          label += ' (' + customer.attributes.origin_name + ')'
        }
        if (customer.attributes.user_id) {
          label += ' - U'
        }

        return {
          id: customer.id,
          name: customer.attributes.name,
          email: customer.attributes.email,
          phoneE164: customer.attributes.phone_e164,
          user_id: customer.attributes.user_id,
          activeBookingId: customer.attributes.active_booking_id,
          label: label,
        }
      })
    },
    durationOptions() {
      let options = []

      if (this.service && this.service.duration) {
        for (let i = 1; i < 4; i++) {
          options = [
            ...options,
            {
              label: this.timeFormat(i * this.service.duration),
              value: i,
            },
          ]
        }
      }

      return options
    },
    guestsOptions() {
      let options = []

      for (let i = 1; i < 11; i++) {
        options = [
          ...options,
          {
            label: i,
            value: i,
          },
        ]
      }

      return options
    },
    totalPrice() {
      return this.form.fields.quantity * this.form.fields.service_price * this.totalPersons
    },
    totalPersons() {
      return this.form.fields.adults_number + this.form.fields.children_number
    },
    isFormReady() {
      return this.form.fields.customer_id && this.form.fields.from
    },
    serviceOptions() {
      return this.services.map((service) => {
        return {
          value: service.id,
          label: service.name
        }
      })
    },
  },
  data() {
    return {
      form: new Form({
        service_id: null,
        service_type: 'activity',
        service_price: null,
        date: null,
        from: null,
        quantity: 1,
        adults_number: 1,
        children_number: 0,
        customer_id: null,
        note: null,
        non_scheduled_booking_id: null,
        origin_platform: Booking.originPlatformBackoffice,
      }),
      createCustomerDialog: false,
      datepicker: new Date(),
      phoneNumber: null,
      timeslots: [],
      loading: false,
      search: null,
      performCustomerSearch: debounce(() => {
        if (!this.search) {
          this.searchedCustomers = []
          return
        }

        this.loading = true

        const params = {
          limit: 10,
          search: this.search,
          include: 'activeBooking',
        }

        this.customerSearch({
          params,
        })
          .then((response) => {
            this.searchedCustomers = response
          })
          .finally(() => {
            this.loading = false
          })
      }, 500),
      searchedCustomers: [],
      selectedCustomer: null,
      newCustomerModalActive: false,
      newCustomerId: null,
      isNonScheduledBooking: false,
      services: [],
      selectedServiceId: this.serviceId,
      formStatusOptions: Booking.formStatusOptions,
      packageOptions: Booking.packageOptions,
      error: null,
    }
  },
  mounted() {
    this.loadServices();

    this.fillForm()

    if (this.date) {
      this.loadServiceAvailability()
      this.datepicker = this.date
    }

    if (this.customerId) {
      this.loadCustomer();
    }
  },
  methods: {
    ...mapActions({
      bookingStore: 'booking/store',
      customerSearch: 'customer/search',
      customerShow: 'customer/show',
      serviceAvailability: 'service/availability',
      servicePricing: 'service/pricing',
      serviceIndex: 'service/index'
    }),
    handlePay() {
      const paymentData = {
        service: this.service,
        booking: this.booking,
        form: this.form,
      }

      this.$refs.bookingPaymentDialog.setPaymentData(paymentData)
      this.$refs.bookingPaymentDialog.open = true
    },
    handleAddToBill() {
      this.loading = true
      this.clearError()
      this.form.errors.clear()

      const fields = {
        ...this.form.fields,
        price: this.booking.price,
        add_to_bill: true, // important
      }

      this.bookingStore(fields)
        .then((response) => {
          this.clearForm()
          this.handleSuccess()
        })
        .catch((response) => {
          if (response.response.data && response.response.data.message) {
            this.showError(response.response.data.message)
          } else if (response.response.data && response.response.data.errors && response.response.data.errors.length) {
            this.showError(response.response.data.errors[0].detail)
          }
          this.form.recordErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleConfirm() {
      this.loading = true
      this.clearError()
      this.form.errors.clear()

      this.bookingStore({
        ...this.form.fields,
        price: this.booking.price,
      })
        .then(() => {
          this.clearForm()
          this.handleSuccess()
        })
        .catch((response) => {
          if (response.response.data && response.response.data.message) {
            this.showError(response.response.data.message)
          } else if (response.response.data && response.response.data.errors && response.response.data.errors.length) {
            this.showError(response.response.data.errors[0].detail)
          }
          this.form.recordErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSuccess() {
      this.$emit('success')
    },
    handleClose() {
      this.clearForm()
      this.$emit('close')
    },
    handleCustomerSearch() {
      this.performCustomerSearch()
    },
    handleCustomerSelect(customer) {
      if (!customer) {
        return
      }

      this.selectedCustomer = customer

      setTimeout(() => {
        this.search = null
      }, 300)
    },
    handleClearCustomer() {
      this.selectedCustomer = null
    },
    handleCreateCustomer() {
      this.$refs.createCustomerDialog.open = true
    },
    handleNewCustomer(customerId) {
      this.newCustomerId = customerId
      this.newCustomerModalActive = false
      this.selectedCustomer = this.newCustomer
    },
    handleDateChange() {
      this.form.fields.date = this.datepicker ? moment(this.datepicker).format('YYYY-MM-DD') : null

      this.resetForm();
      this.loadServiceAvailability()
    },
    handleGroupChange() {
      this.resetForm();
      this.loadServiceAvailability()
    },
    handleServiceSelect() {
      this.form.fields = {
        ...this.form.fields,
        service_id: this.service.id,
        service_price: this.service.price,
      }
      this.resetForm();

      this.loadServicePricing();
      this.loadServiceAvailability();
    },
    loadServices() {
      this.loading = true;

      this.serviceIndex({}).then((response) => {
        this.services = this.$store.getters['service/collection'](response.ids)
      }).finally(() => {
        this.loading = false;
      })
    },
    loadCustomer() {
      return this.customerShow({
        id: this.customerId,
        params: {
          include: ['user'],
        },
      }).then((response) => {
        this.selectedCustomer = this.$store.getters['customer/findBy'](response[0], 'id')
      })
    },
    loadServiceAvailability() {
      if (!this.service) {
        return;
      }

      this.loading = true
      const params = {
        type: 'activity',
        ...this.form.fields,
      }

      if (this.nonScheduledBookingId) {
        params.non_scheduled_booking = this.nonScheduledBookingId
      }

      const data = {
        id: this.service.id,
        params: params,
      }

      return this.serviceAvailability(data)
        .then((response) => {
          this.timeslots = response.availability
          this.isNonScheduledBooking = response.isNonScheduled
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadServicePricing() {
      if (!this.service) {
        return;
      }
      if (!this.selectedCustomer) {
        this.form.fields.service_price = this.service.price
        return;
      }

      this.loading = true

      const payload = {
        id: this.service.id,
        params: {
          customer_id: this.selectedCustomer.id,
          origin_platform: Booking.originPlatformBackoffice,
        },
      }

      return this.servicePricing(payload)
        .then((response) => {
          this.form.fields.service_price = response.price
        })
        .finally(() => {
          this.loading = false
        })
    },
    dateFormat(value) {
      return moment(value).format('ddd, D MMM YYYY')
    },
    fillForm() {
      this.form.fields = {
        ...this.form.fields,
        service_id: this.service ? this.service.id : null,
        service_price: this.service ? this.service.price : null,
        date: this.date ? moment(this.date).format('YYYY-MM-DD') : null,
        quantity: 1,
        adults_number: 1,
        non_scheduled_booking_id: this.nonScheduledBookingId,
      }
    },
    clearForm() {
      this.form.fields = {
        service_id: null,
        service_type: 'activity',
        service_price: null,
        date: null,
        from: null,
        quantity: 1,
        adults_number: 1,
        children_number: 0,
        customer_id: null,
        non_scheduled_booking_id: null
      }
    },
    resetForm() {
      this.$refs.timeslotInput?.disableAllTimeslots();
      this.form.fields.from = null;
    },
    timeFormat(value) {
      const hours = Math.floor(value / 60)
      const minutes = value % 60

      let values = []

      if (hours) {
        if (hours > 1) {
          values.push(hours + ' hours')
        } else {
          values.push(hours + ' hour')
        }
      }

      if (minutes) {
        values.push(minutes + ' minutes')
      }

      return values.join(' ')
    },
    groupFormat(adults, children) {
      let values = []

      if (adults) {
        if (adults > 1) {
          values.push(adults + ' adults')
        } else {
          values.push(adults + ' adult')
        }
      }

      if (children) {
        if (children > 1) {
          values.push(children + ' children')
        } else {
          values.push(children + ' child')
        }
      }

      return values.join(' ')
    },
    showError(message) {
      this.error = message
    },
    clearError() {
      this.error = null
    },
    formatDatepickerDate(date) {
      return date
        ? date.toLocaleDateString('en-us', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })
        : ''
    },
  },
  watch: {
    selectedCustomer() {
      if (this.selectedCustomer) {
        this.form.fields.customer_id = this.selectedCustomer.id
      } else {
        this.form.fields.customer_id = null
      }

      this.loadServicePricing()
    },
    activeBooking() {
      if (this.activeBooking) {
        this.form.fields.adults_number = this.activeBooking.totalPersons;
        this.datepicker = this.activeBooking.startAt.toDate();
      }
    },
  },
}
</script>
