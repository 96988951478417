import {
    Model
} from '@/internal/modules';

import {
  ServiceRuleService as ServiceRuleService,
  Unavailability
} from '@/internal';


export default class ServiceRule extends Model {

    static get name() {
        return 'ServiceRule';
    }

    static get vuexModuleKey() {
        return 'serviceRule';
    }

    static get service() {
        return ServiceRuleService;
    }

    get unavailabilities() {
      return this.hasMany(Unavailability);
    }

    static get fields () {
        return {};
    }

    static get typeOptions() {
        return [
            {
                label: 'Max persons per booking',
                value: 'max-persons-per-booking',
            },
            {
                label: 'Max persons at same time',
                value: 'max-persons-at-same-time',
            },
            {
                label: 'Max bookings at same time',
                value: 'max-bookings-at-same-time',
            },
            {
                label: 'Max persons starting at same time',
                value: 'max-persons-starting-at-same-time',
            },
            {
                label: 'Max bookings starting at same time',
                value: 'max-bookings-starting-at-same-time',
            },
            {
                label: 'Max persons per interval',
                value: 'max-persons-per-interval',
            },
            {
                label: 'Max bookings per interval',
                value: 'max-bookings-per-interval',
            },


        ]
    }
}
