<template>
  <SideDrawer _class="w:640@lg" :loading="loading" @close="$emit('close')">
    <div class="p:24">
      <label class="--label mb:32">
        Name
        <input type="text" class="--input mt:4" v-model="form.fields.name" />
      </label>

      <label class="--label mb:32">
        Description
        <textarea class="--textarea mt:4 min-h:120" v-model="form.fields.description" />
      </label>

      <label class="--label mb:32">
        Important info
        <textarea class="--textarea mt:4 min-h:120" v-model="form.fields.importantInfo" />
      </label>

      <div class="flex:|center gap:16 mb:32">
        <div class="flex:1">
          <label class="--label mb:4">Price</label>
          <input type="number" class="--input mt:4" v-model="form.fields.price" />
        </div>
        <div class="flex:1">
          <label class="--label mb:4">Tax</label>
          <input type="number" class="--input mt:4" v-model="form.fields.taxRate" />
        </div>
      </div>

      <div class="flex:|center gap:16 mb:32">
        <div class="flex:1">
          <label class="--label mb:4">Category</label>
          <SelectInput v-model="form.fields.categoryId" :options="categoryOptions" />
        </div>
        <div class="flex:1">
          <label class="--label mb:4">Status</label>
          <SelectInput v-model="form.fields.status" :options="statusOptions" />
        </div>
      </div>

      <label class="--label mb:32 flex:|center gap:8">
        <input type="checkbox" class="--checkbox" v-model="form.fields.createFromPackage" />
        Display in booking summary
      </label>

      <label class="--label mb:4">Images</label>
      <FileUpload v-model="form.fields.files" :entity="product" :multiple="true" :extensions="['jpg', 'jpeg', 'png']" />
    </div>

    <template slot="footer">
      <button
        v-if="isEdit"
        @click="handleDelete"
        class="reset flex:|center gap:8 f:16 semi c:error py:12 r:8 o:0.6:hover"
      >
        <Icon admin name="trash" />
        Delete
      </button>
      <button
        class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
        @click="handleSave"
      >
        <Icon admin name="check" />
        Save
      </button>
    </template>
  </SideDrawer>
</template>

<script>
import { mapActions } from 'vuex'

import { Form } from '@/internal/modules'

import { Category, Product } from '@/internal'

import FileUpload from '@/views/Admin/components/FileUpload'

import SideDrawer from '@/components/admin/SideDrawer.vue'
export default {
  name: 'ProductModal',
  components: {
    SideDrawer,
    FileUpload,
  },
  computed: {
    categories() {
      return this.categoryIds ? this.$store.getters['category/collection'](this.categoryIds) : []
    },
    categoryOptions() {
      return this.categories
        ? [
            {
              label: '-- Select Category --',
              value: null,
            },
            ...this.categories.map((category) => {
              return {
                label: category.name,
                value: parseInt(category.id),
              }
            }),
          ]
        : []
    },
    isEdit() {
      return this.$route.query.show !== 'create'
    },
    id() {
      return this.isEdit ? this.$route.query.show : null
    },
    product() {
      return this.id ? this.$store.getters['product/show'](this.id) : null
    },
  },
  mounted() {
    this.loadCategories()

    if (this.product) {
      this.form.fields = {
        ...this.product,
        files: this.product.files ? this.product.files : [],
      }
    }
  },
  data() {
    return {
      categoryIds: [],
      form: new Form({
        name: '',
        description: '',
        status: Product.statusOptions[0].value,
        createFromPackage: false,
        files: [],
      }),
      loading: false,
      statusOptions: Product.statusOptions,
    }
  },
  methods: {
    ...mapActions({
      productDestroy: 'product/destroy',
      productStore: 'product/store',
      productUpdate: 'product/update',
      categoryIndex: 'category/index',
    }),
    handleSave() {
      this.loading = true
      this.form.errors.clear()

      const fields = {
        ...this.form.fields,
        categories: this.form.fields.categoryId ? [this.form.fields.categoryId] : [],
      }

      let action = null

      if (this.isEdit) {
        action = this.productUpdate
      } else {
        action = this.productStore
      }

      action(Product.preparePayload(fields))
        .then(() => {
          window.app.snackbar({
            message: this.isEdit ? 'Updated successfully!' : 'Created successfully!',
          })
          this.$router.push('/admin/product')
        })
        .catch((error) => {
          this.form.recordErrors(error)
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleDelete() {
      window.app.confirm({
        title: 'Delete Product',
        message: 'Are you sure you want to delete this product?',
        onConfirm: () => {
          this.loading = true
          this.productDestroy({
            id: this.product.id,
          })
            .then(() => {
              window.app.snackbar('Product Deleted')

              this.$router.push('/admin/product')
            })
            .catch((e) => {
              if (e.response && e.response.data) {
                window.app.snackbar({
                  message: e.response.data.message,
                  type: 'is-danger',
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    },
    loadCategories() {
      this.categoryIndex({
        params: {
          entityType: Category.entityTypeProduct,
        },
      })
        .then((response) => {
          this.categoryIds = response.ids
        })
        .catch((error) => {})
        .finally(() => {})
    },
  },
  watch: {
    product() {
      if (this.product) {
        this.form.fields = {
          ...this.product,
          files: this.product.files ? this.product.files : [],
        }
      }
    },
  },
}
</script>
