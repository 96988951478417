<template>
  <div>
    <div v-if="service">
      <div class="p:24">
        <div class="b:base-20 r:12">
          <div class="flex:between|center p:24|12">
            <div class="f:24 c:accent-70 semi">Rules</div>
            <button
              @click="$refs.rulesDialog.open = true"
              class="reset flex:|center gap:8 f:16 semi p:8|12 r:8 o:0.9:hover bg:primary c:white"
            >
              <Icon admin name="plus" />
              Add New
            </button>
          </div>
          <b-table v-if="rules.length" :data="rules" :striped="true" :hoverable="true" :sortable="true">
            <b-table-column field="dayOfTheWeek" label="Rule" v-slot="props">
              {{ props.row.label }}
              <template v-if="hasInterval(props.row)">{{ props.row.startTime }} - {{ props.row.endTime }}</template>
            </b-table-column>

            <b-table-column field="from" label="Value" v-slot="props">
              {{ props.row.value }}
            </b-table-column>

            <b-table-column label="" v-slot="props">
              <b-dropdown
                :ref="`contextRow${props.row.id}`"
                aria-role="list"
                position="is-bottom-left"
                class="has-text-left is-clickable"
              >
                <template #trigger="{ active }">
                  <div @click.prevent.stop="handleRowActionClick(props.row)">
                    <BIcon icon="ellipsis" />
                  </div>
                </template>

                <b-dropdownItem aria-role="listitem" class="pr-0">
                  <div @click.prevent.stop="handleUpdate(props.row)">
                    <BIcon icon="pencil-alt" />
                    Update
                  </div>
                </b-dropdownItem>

                <b-dropdownItem aria-role="listitem" class="pr-0">
                  <div @click.prevent.stop="handleDelete(props.row)">
                    <BIcon icon="trash-alt" />
                    Delete
                  </div>
                </b-dropdownItem>
              </b-dropdown>
            </b-table-column>
          </b-table>
          <div v-else class="flex:center p:24">
            <div class="f:16 c:accent-70">No rules added</div>
          </div>
        </div>
      </div>
    </div>
    <RulesDialog ref="rulesDialog" @onSave="handleRuleAddSuccess" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import RulesDialog from './RulesDialog.vue'

export default {
  name: 'Rules',
  components: {
    RulesDialog,
  },
  computed: {
    service() {
      return this.serviceId ? this.$store.getters['service/show'](this.serviceId) : null
    },
    timezone() {
      return this.$store.state.system.timezone
    },
    rules() {
      return this.service ? this.service.rules : []
    },
  },
  data() {
    return {
      paginator: null,
      loading: false,
      serviceId: null,
      rulesDialogActive: false,
      activeRule: null,
    }
  },
  mounted() {
    this.loadService()
  },
  methods: {
    ...mapActions({
      serviceShow: 'service/show',
      serviceRuleDestroy: 'serviceRule/destroy',
    }),
    handleRowActionClick(row) {
      this.$refs['contextRow' + row.id].toggle()
    },
    handleUpdate(row) {
      this.$refs.rulesDialog.fillForm(row)
      this.$refs.rulesDialog.setServiceRule(row)
      this.$refs.rulesDialog.open = true
    },
    handleRuleAddSuccess() {
      this.loadService()
    },
    handleDelete(row) {
      window.app.confirm({
        title: 'Delete Rule',
        message: 'Are you sure you want to delete this rule?',
        onConfirm: () => {
          this.loading = true

          this.serviceRuleDestroy({ id: row.id })
            .then(() => {
              window.app.snackbar('Service rule deleted')
              this.loadService()
            })
            .catch((e) => {
              if (e.response && e.response.data) {
                window.app.snackbar({
                  message: e.response.data.message,
                  type: 'is-danger',
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    },
    loadService() {
      this.loading = true

      return this.serviceShow({
        id: this.$route.query.show,
        params: {
          include: ['serviceRules'],
        },
      })
        .then((response) => {
          this.serviceId = response[0]
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false
        })
    },
    hasInterval(rule) {
      return ['max-persons-per-interval', 'max-bookings-per-interval'].includes(rule.type)
    },
  },
}
</script>
