<template>
  <div>
    <div class="mb:32">
      <div class="flex:between|center">
        <label class="--label">
          Exceptions
        </label>
        <button
          @click="handleCreateUnavailability"
          class="reset flex:|center gap:8 f:16 semi c:accent py:8 o:0.9:hover"
        >
          <Icon admin name="plus-circle" />
          Add new
        </button>
      </div>
      <table class="styled striped" style="overflow:visible !important" v-if="unavailabilities.length">
        <tr v-for="unavailability in unavailabilities">
          <td>{{ formatName(unavailability) }}</td>
          <td>
            <b-dropdown :ref="`unavailabilityContext${unavailability.id}`"
                        aria-role="list"
                        class="is-clickable"
                        position="is-bottom-left">
              <template #trigger="{ active }">
                <div class="px-2" @click.prevent.stop="unavailabilityContextClick(unavailability)">
                  <i class="fa-solid fa-ellipsis-vertical"></i>
                </div>
              </template>

              <b-dropdownItem aria-role="listitem" >
                <div @click.prevent.stop="handleUpdateUnavailability(unavailability)">
                  Update
                </div>
              </b-dropdownItem>

              <b-dropdownItem aria-role="listitem" >
                <div @click.prevent.stop="handleDestroyUnavailability(unavailability)">
                  Remove
                </div>
              </b-dropdownItem>
            </b-dropdown>
          </td>
        </tr>
      </table>
      <div v-else class="p:20 flex:|center gap:12 b:base-20 r:8">
        <Icon admin name="x" class="c:error" />
        No exceptions
      </div>
    </div>

    <UnavailabilityDialog ref="unavailabilityDialog" @success="handleUnavailabilitySuccess" />
  </div>
</template>

<script>
import UnavailabilityDialog from '@/components/admin/UnavailabilityDialog.vue'
import { mapActions } from 'vuex'
import moment from "moment-timezone";

export default {
  name: 'UnavailabilityInput',
  components: {
    UnavailabilityDialog
  },
  props: {
    entityId: {
      type: String|null,
      required: false,
    },
    entityType: {
      type: String,
      required: true,
    },
    entityUnavailabilities: {
      type: Array,
      required: false
    }
  },
  computed: {
    isUpdate() {
      return !!this.entityId
    },
    timezone() {
      return this.$store.state.system.timezone;
    },
  },
  data() {
    return {
      unavailabilities: [],
    }
  },
  mounted() {
    this.unavailabilities = [
      ...this.entityUnavailabilities
    ]
  },
  methods: {
    ...mapActions({
      unavailabilityDestroy: 'unavailability/destroy',
    }),
    localTimezone(date) {
      return moment.utc(date).tz(this.timezone);
    },
    formatName(unavailability) {
      let startAt = this.localTimezone(unavailability.startAt).format('MMMM Do YYYY, HH:mm');
      let endAt = this.localTimezone(unavailability.endAt).format('MMMM Do YYYY, HH:mm');

      return startAt + ' - ' + endAt;
    },
    inputEvent() {
      this.$emit('input', this.unavailabilities.map(unavailability => unavailability.id));
    },
    handleUnavailabilitySuccess(unavailabilityId) {
      this.addOrUpdateUnavailability(unavailabilityId);
      this.inputEvent();
    },
    unavailabilityContextClick(unavailability) {
      this.$refs['unavailabilityContext' + unavailability.id][0].toggle();
    },
    addOrUpdateUnavailability(unavailabilityId) {
      const unavailability = this.$store.getters['unavailability/findBy'](unavailabilityId);
      const unavailabilityIndex = this.unavailabilities.findIndex(function(unavailability) {
        return unavailability.id === unavailabilityId;
      });

      if (unavailabilityIndex < 0) { // Add
        this.unavailabilities = [
          ...this.unavailabilities,
          unavailability
        ];
      } else { // Update
        this.$set(this.unavailabilities, unavailabilityIndex, unavailability);
      }
    },
    removeUnavailability(unavailabilityId) {
      const unavailabilityIndex = this.unavailabilities.findIndex(function(unavailability) {
        return unavailability.id === unavailabilityId;
      });
      this.unavailabilities.splice(unavailabilityIndex, 1)
    },
    handleCreateUnavailability() {
      this.$refs.unavailabilityDialog.setFormFields({
        entityId: this.isUpdate ? this.entityId : 0,
        entityType:this.entityType
      });
      this.$refs.unavailabilityDialog.open = true;
    },
    handleUpdateUnavailability(unavailability) {
      this.$refs.unavailabilityDialog.setFormFields(unavailability);
      this.$refs.unavailabilityDialog.open = true;
    },
    handleDestroyUnavailability(unavailability) {
      window.app.confirm({
        title: 'Remove Exception',
        message: 'Are you sure you want to remove this exception?',
        onConfirm: () => {
          this.loading = true
          this.unavailabilityDestroy({
            id: unavailability.id,
          }).then(() => {
            this.removeUnavailability(unavailability.id);
            this.inputEvent();
            window.app.snackbar('Exception removed')
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              })
            }
          }).finally(() => {
            this.loading = false
          })
        },
      })
    },
  }
};
</script>

<style>
</style>
