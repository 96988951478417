<template>
  <div class="rel p:24">
    <div v-if="loading" class="flex:center|center">
      <b-loading :is-full-page="false" v-model="loading" />
    </div>
    <template>
      <div class="flex:between|center mb:24">
        <SelectInput v-model="form.fields.status" :options="statusOptions" placeholder="Select status" />
        <div v-if="form.fields.accountIntegrationId">
          <div class="bg:accent-10 f:14 semi p:4|8 r:4">
            {{ form.fields.accountIntegrationName }}
          </div>
        </div>
      </div>
      <div class="flex-col gap:12 mb:24">
        <div class="section-title">
          <Icon admin name="spark" />
          Service Details
        </div>
        <label class="--label">
          Name
          <input type="text" class="--input mt:4" v-model="form.fields.name" />
          <form-error field="name" :form="form" />
        </label>
        <label class="--label">
          Title
          <input type="text" class="--input mt:4" v-model="form.fields.title" />
          <form-error field="title" :form="form" />
        </label>
        <label class="--label">
          Description
          <textarea class="--textarea mt:4" rows="8" v-model="form.fields.description" />
          <form-error field="description" :form="form" />
        </label>
        <label class="--label">
          Related services
          <b-taginput
            class="mt:4"
            v-model="form.fields.relatedServices"
            :data="filteredServices"
            autocomplete
            :allow-new="false"
            :open-on-focus="true"
            :allow-duplicates="false"
            :before-adding="checkRelatedServicesDuplicates"
            field="name"
            placeholder="Add a service"
            @typing="getFilteredServices"
          />
        </label>

        <div class="--label w:50% flex-col gap:4">
          Type
          <SelectInput
            v-model="form.fields.subtype"
            :options="subtypeOptions"
            placeholder="Select type"
          />
          <form-error field="subtype" :form="form" />
        </div>

        <div class="--label w:50% flex-col gap:4">
          Integrations
          <SelectInput
            v-model="form.fields.accountIntegrationId"
            :options="accountIntegrationOptions"
            placeholder="Select integration"
          />
          <form-error field="account_integration_id" :form="form" />
        </div>

        <div class="--label pt:4 w:50% flex-col gap:4">
          Guest availability
          <SelectInput
            v-model="form.fields.guestAvailability"
            :options="guestAvailabilityOptions"
            placeholder="Select availability"
          />
          <FormError field="guest_availability" :form="form" />
        </div>
      </div>
      <div class="section-title">
        <Icon admin name="clock" />
        Time
      </div>
      <div class="flex gap:12 mb:12">
        <label class="--label w:100%">
          Duration
          <input type="number" class="--input mt:4" v-model="form.fields.duration" />
          <form-error field="duration" :form="form" />
        </label>
        <div class="--label w:100% flex-col gap:4">
          Timeslot Duration
          <SelectInput
            v-model="form.fields.timeslotDuration"
            :options="timeslotDurationOptions"
            placeholder="Select timeslot duration"
          />
          <form-error field="timeslot_duration" :form="form" />
        </div>
      </div>
      <div class="flex gap:12 mb:12">
        <label class="--label w:100%">
          Buffer before
          <input type="number" class="--input mt:4" v-model="form.fields.bufferTimeBefore" />
          <form-error field="buffer_time_before" :form="form" />
        </label>
        <label class="--label w:100%">
          Buffer after
          <input type="number" class="--input mt:4" v-model="form.fields.bufferTimeAfter" />
          <form-error field="buffer_time_after" :form="form" />
        </label>
      </div>
      <div class="flex:|center mb:24 gap:12">
        <div class="--label w:50% flex-col gap:4">
          Booking Interval
          <SelectInput
            v-model="form.fields.bookingInterval"
            :options="bookingIntervalOptions"
            placeholder="Select booking interval"
          />
          <form-error field="booking_interval" :form="form" />
        </div>

        <label class="--label w:50% pt:32">
          <b-checkbox v-model="form.fields.configuration.flexibleTime">Flexible time</b-checkbox>
        </label>
      </div>
      <div class="section-title">
        <Icon admin name="price-tag" />
        Prices
      </div>
      <div class="flex gap:12 mb:12">
        <label class="--label w:100%">
          Resident Price
          <input type="number" class="--input mt:4" v-model="form.fields.priceResident" />
          <form-error field="price_resident" :form="form" />
        </label>
        <label class="--label w:100%">
          Non-Resident Price
          <input type="number" class="--input mt:4" v-model="form.fields.priceNonResident" />
          <form-error field="price_non_resident" :form="form" />
        </label>
      </div>
      <div class="flex gap:12 mb:12">
        <label class="--label w:100%">
          Tax
          <input type="number" class="--input mt:4" v-model="form.fields.taxRate" />
          <form-error field="tax_rate" :form="form" />
        </label>
        <label class="--label w:100%">
          Price description
          <input type="text" class="--input mt:4" v-model="form.fields.priceDescription" />
          <form-error field="price_description" :form="form" />
        </label>
      </div>
      <div class="flex gap:12 mb:24">
        <div class="--label w:100% flex-col gap:4">
          Payment
          <SelectInput
            v-model="form.fields.paymentType"
            :options="paymentTypeOptions"
            placeholder="Select payment type"
          />
          <form-error field="payment_type" :form="form" />
        </div>
        <div class="--label w:100% flex-col gap:4">
          Charging
          <SelectInput
            v-model="form.fields.chargingType"
            :options="chargingTypeOptions"
            placeholder="Select charging type"
          />
          <form-error field="charging_type" :form="form" />
        </div>
      </div>

      <div class="section-title mb:12">
        <Icon admin name="folders" />
        Categories
      </div>
      <div class="mb:24">
        <ul>
          <li v-for="category in topLevelCategories">
            <label class="--label flex:|center gap:12 mb:12">
              <input class="--checkbox" type="checkbox" v-model="form.fields.categories" :value="category.id" />
              {{ category.name }}
            </label>

            <ul style="padding-inline-start: 20px">
              <li v-for="subcategory in category.subcategories">
                <label class="--label flex:|center gap:12 mb:12">
                  <input class="--checkbox" type="checkbox" v-model="form.fields.categories" :value="subcategory.id" />
                  {{ subcategory.name }}
                </label>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!--Show product select only if integration is outside of MEWS-->
      <div v-if="isEdit && form.fields.originName !== 'Mews'" class="field">
        <div class="section-title">
          <Icon admin name="puzzle" class="w:22 h:22" />
          Products
        </div>
        <b-autocomplete
          v-model="selectedProductName"
          placeholder="Start typing and select a product..."
          :open-on-focus="true"
          :data="filteredProducts"
          field="nameWithOriginId"
          @select="handleProductSelect"
          :clear-on-select="true"
          :clearable="true"
          class="mb:12"
        />

        <template v-if="form.fields.serviceProducts && form.fields.serviceProducts.length > 0">
          <div
            v-for="(serviceProduct, index) in form.fields.serviceProducts"
            class="flex:|center gap:12 p:12 bb:primary-10/50"
          >
            <div class="flex:1">{{ serviceProduct.productName }}</div>
            <div>
              <b-tooltip label="Set as default" position="is-left">
                <label class="flex:|center" :class="`${serviceProduct.default ? 'c:accent' : 'c:base-20'}`">
                  <Icon admin name="star" class="size:24" />
                  <input
                    type="radio"
                    class="hide"
                    :value="true"
                    v-model="serviceProduct.default"
                    @input="handleDefaultServiceProduct(serviceProduct)"
                  />
                </label>
              </b-tooltip>
            </div>
            <div>
              <button class="reset c:error" @click="handleServiceProductRemove(index)">
                <Icon admin name="trash" class="size:24" />
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <p>No products added</p>
        </template>
        <FormError field="service_products" :form="form" />
      </div>

      <div class="section-title">
        <Icon admin name="image" />
        Image
      </div>
      <FileUpload v-model="form.fields.files" :entity="service" :multiple="true" :extensions="['jpg', 'jpeg', 'png']" />
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import { Form } from '@/internal/modules'

import { Category, Service } from '@/internal'

import FileUpload from '@/views/Admin/components/FileUpload.vue'
import FormMenu from '@/views/Admin/Service/FormMenu.vue'

export default {
  name: 'GeneralTab',
  components: {
    FileUpload,
    FormMenu,
  },
  data() {
    return {
      serviceId: null,
      categoryIds: [],
      productIds: [],
      serviceIds: [],
      accountIntegrationIds: [],
      form: new Form({
        name: '',
        description: '',
        category_id: null,
        files: [],
        categories: [],
        services: [],
        relatedServices: [],
        serviceProducts: [],
        originData: {},
        configuration: {},
        type: 'activity',
        subtype: null,
        status: Service.statusOptions[0].value,
        chargingType: Service.chargingTypeOptions[0].value,
        limitationType: Service.chargingTypeOptions[0].value,
      }),
      loading: false,
      statusOptions: Service.statusOptions,
      guestAvailabilityOptions: Service.guestAvailabilityOptions,
      chargingTypeOptions: Service.chargingTypeOptions,
      timeslotDurationOptions: Service.timeslotDurationOptions,
      paymentTypeOptions: Service.paymentTypeOptions,
      limitationTypeOptions: Service.limitationTypeOptions,
      bookingIntervalOptions: Service.bookingIntervalOptions,
      subtypeOptions: Service.subtypeOptions,
      selectedProductName: '',
      filteredServices: [],
    }
  },
  computed: {
    categories() {
      return this.categoryIds ? this.$store.getters['category/collection'](this.categoryIds) : []
    },
    topLevelCategories() {
      return this.categories.filter((category) => {
        return !category.categoryId
      })
    },
    products() {
      return this.productIds ? this.$store.getters['product/collection'](this.productIds) : []
    },
    service() {
      return this.serviceId ? this.$store.getters['service/findBy'](this.serviceId) : null
    },
    services() {
      return this.serviceIds ? this.$store.getters['service/collection'](this.serviceIds) : null
    },
    accountIntegrations() {
      return this.accountIntegrationIds
        ? this.$store.getters['accountIntegration/collection'](this.accountIntegrationIds).map((accountIntegration) => {
            return {
              value: parseInt(accountIntegration.id),
              label: accountIntegration.name,
            }
          })
        : []
    },
    accountIntegrationOptions() {
      return [
        {
          value: null,
          label: "N/A"
        },
        ...this.accountIntegrations
      ]
    },
    filteredProducts() {
      return this.products
        ? this.products.filter((option) => {
            return option.name.toLowerCase().indexOf(this.selectedProductName.toLowerCase()) >= 0
          })
        : []
    },
    selectedProduct() {
      return this.products && this.form.fields.originData && this.form.fields.originData.productId
        ? this.products.find((product) => {
            return product.id === this.form.fields.originData.productId
          })
        : null
    },
    isEdit() {
      return this.$route.query.show !== 'create'
    },
  },
  async mounted() {
    this.loadCategories()
    this.loadProducts()
    await this.loadAccountIntegrations()

    await this.loadServices()

    if (this.isEdit) {
      await this.loadService()
    }
  },
  methods: {
    ...mapActions({
      categoryIndex: 'category/index',
      serviceDestroy: 'service/destroy',
      serviceStore: 'service/store',
      serviceUpdate: 'service/update',
      productIndex: 'product/index',
      serviceShow: 'service/show',
      serviceIndex: 'service/index',
      accountIntegrationIndex: 'accountIntegration/index',
    }),
    fillForm() {
      this.form.fields = {
        ...this.service,
        originData: this.service.originData ? this.service.originData : {},
        categories: this.service.categories ? this.service.categories.map((c) => c.id) : [],
        files: this.service.files ? this.service.files : [],
        configuration: this.service.configuration ? this.service.configuration : {},
        relatedServices: this.service.relatedServices ? this.service.relatedServices : [],
        serviceProducts: this.service.serviceProducts ? this.service.serviceProducts : [],
      }
      // Very stupid solution
      //this.productName = this.selectedProduct ? this.selectedProduct.nameWithOriginId : '';
    },
    handleSave() {
      this.loading = true
      this.form.errors.clear()

      const fields = {
        ...this.form.fields,
        configuration: !this.isEmpty(this.form.fields.configuration) ? this.form.fields.configuration : null,
      }

      let action = null

      if (this.isEdit) {
        action = this.serviceUpdate
      } else {
        action = this.serviceStore
      }

      action(Service.preparePayload(fields))
        .then((response) => {
          window.app.snackbar({
            message: 'Saved successfully!',
          })

          if (this.isEdit) {
            this.$emit('onSave')
          } else {
            this.$router.replace(`/admin/service?show=${response}`)
            this.loadService()
          }
        })
        .catch((error) => {
          this.form.recordErrors(error)
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadCategories() {
      return this.categoryIndex({
        params: {
          entityType: Category.entityTypeService,
        },
      })
        .then((response) => {
          this.categoryIds = response.ids
        })
        .catch((error) => {})
        .finally(() => {})
    },
    loadProducts() {
      this.loading = true

      return this.productIndex({
        params: {
          status: 'published',
        },
      })
        .then((response) => {
          this.productIds = response.ids
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false
        })
    },
    loadAccountIntegrations() {
      return this.accountIntegrationIndex()
        .then((response) => {
          this.accountIntegrationIds = response.ids
        })
        .catch((error) => {})
        .finally(() => {})
    },
    loadServices() {
      this.loading = true
      let params = {}

      if (this.$route.params.serviceId) {
        params.id_not_in = this.$route.params.serviceId
      }

      return this.serviceIndex({
        params: params,
      })
        .then((response) => {
          this.serviceIds = response.ids

          this.filteredServices = this.services.map((option) => {
            return {
              id: option.id,
              name: option.name,
            }
          })
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false
        })
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0
    },
    loadService() {
      this.loading = true

      return this.serviceShow({
        id: this.$route.query.show,
        params: {
          include: ['categories', 'relatedServices', 'serviceProducts', 'serviceItems', 'serviceSpaces'],
        },
      })
        .then((response) => {
          this.serviceId = response[0]
          this.fillForm()
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false
        })
    },
    getFilteredServices(text) {
      this.filteredServices = this.services
        .filter((option) => {
          return option.name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0 && option.id !== this.serviceId
        })
        .map((option) => {
          return {
            id: option.id,
            name: option.name,
          }
        })
    },
    checkRelatedServicesDuplicates(service) {
      return !this.form.fields.relatedServices.find((item) => item.id === service.id)
    },
    handleDefaultServiceProduct(serviceProduct) {
      this.form.fields.serviceProducts.forEach((item) => {
        if (item.productId === serviceProduct.productId) {
          item.default = true
        } else {
          item.default = false
        }
      })
    },
    handleServiceProductRemove(index) {
      this.form.fields.serviceProducts.splice(index, 1)
      this.setDefaultServiceProduct()
    },
    handleProductSelect(product) {
      if (!product) {
        return
      }

      // Check if exists already
      let isProductAdded = false
      this.form.fields.serviceProducts.forEach((item) => {
        console.log(String(item.productId))
        console.log(String(product.id))
        if (String(item.productId) === String(product.id)) {
          isProductAdded = true
          return false
        }
      })

      if (isProductAdded) {
        return false
      }

      this.form.fields.serviceProducts = [
        ...this.form.fields.serviceProducts,
        {
          productName: product.name,
          productId: product.id,
          serviceId: this.service.id,
          default: false,
        },
      ]
      this.setDefaultServiceProduct()
      this.selectedProductName = ''
    },
    setDefaultServiceProduct() {
      if (!this.form.fields.serviceProducts || this.form.fields.serviceProducts.length < 1) {
        return
      }
      let hasDefaultServiceProduct = this.form.fields.serviceProducts.find((item) => item.default)
      if (!hasDefaultServiceProduct) {
        this.form.fields.serviceProducts[0].default = true
      }
    },
  },
}
</script>

<style>
.b-tooltip.is-primary .tooltip-content {
  background: var(--color-accent) !important;
}
.b-tooltip.is-left.is-primary .tooltip-content::before {
  border-left-color: var(--color-accent) !important;
}
</style>
