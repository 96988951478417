<template>
    <div class="background" :style="backgroundImageStyle">
        <div class="is-flex is-flex-direction-column is-justify-content-space-between">
            <div class="text-center mt-4">
                <img :src="account && account.branding.logoUrl" :alt="account && account.branding.name" style="max-width: 240px;">
            </div>
            <div class="welcome_title text-center has-text-white font-primary fs-55 line-height-1">
              {{ account && account.branding.welcomeImageText }}
            </div>
            <div class="columns">
                <div class="column text-center">
                    <button
                        class="login-btn button is-white pointer is-fullwidth is-flex is-justify-content-start border-radius-12"
                        @click="$router.push({name: 'auth/login'})"
                    >
                        <div class="mx-2">
                            <img class="login-btn__img icon__social-media mr-2" src="/images/envelope2.svg" alt="Envelope">
                        </div>
                        <div class="has-text-weight-medium">Continue with email</div>
                    </button>
                </div>
                <!--  Google login-->
                <div class="column text-centered">
                    <button
                        id="button-google"
                        class="login-btn button is-white pointer is-fullwidth is-flex is-justify-content-start border-radius-12"
                        @click="handleGoogleLogin"
                    >
                        <div class="mx-2">
                            <img class="login-btn__img icon__social-media mr-2" src="/images/google2.svg" alt="Google logo">
                        </div>
                        <div class="has-text-weight-medium">Continue with google</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: "Welcome",
    computed: {
        user() {
            return this.$store.getters['auth/user'] ;
        },
        account() {
          return this.$store.getters['account/current'];
        },
        backgroundImageStyle() {
          return {
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.65) 6.25%, rgba(0, 0, 0, 0) 60.94%, rgba(0, 0, 0, 0) 100%), url(${this.account && this.account.branding.welcomeImageUrl})`
          }
        }
    },
    methods: {
        ...mapActions({
            googleLogin: 'auth/googleLogin',
            facebookLogin: 'auth/facebookLogin'
        }),
        handleGoogleLogin() {
            window.location.href = "auth/google";
        },
        // Google
        async initGoogle() {
            google.accounts.id.initialize({
                client_id: process.env.GOOGLE_CLIENT_ID,
                callback: this.handleGoogleCallback
            });

            //google.accounts.id.prompt();
        },
    }
}
</script>

<style lang="scss" scoped>
    .background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;

        & > div {
            height: 100%;
            padding: 30px 20px;
        }
    }
    .login-btn {
        &__img {
            width: 30px;
        }

        &__text {
            //width: 100%;
        }
    }

    .icon__envelope {
        margin-left: -9px
    }

    .icon__social-media {
        margin-left: -10px
    }

    .welcome_title {
        text-shadow: 0px 0px 13px rgba(0, 0, 0, 0.78);
    }

</style>
