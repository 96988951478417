
import {
    Model,
} from '@/internal/modules';

import {
    Customer,
    Service,
    Category,
    BookingGroup,
    NonScheduledBooking,
    BookingService as BookingService
} from '@/internal';

export default class Booking extends Model {
    static get name() {
        return 'Booking';
    }
    static get vuexModuleKey() {
        return 'booking';
    }
    static get service() {
        return BookingService;
    }

    static get fields () {
        return {
            status: {
                required: true,
                type: String,
            },
            startAt: {
                type: Date,
            },
            endAt: {
                type: Date,
            },
            createdAt: {
                type: Date
            },
            groupStartAt: {
              type: Date || null,
            },
            groupEndAt: {
              type: Date || null,
            }
        };
    }

    get customers() {
        return this.hasMany(Customer)
    }

    get services() {
        return this.hasMany(Service);
    }

    get category() {
        return this.hasOne(Category)
    }

    get group() {
        return this.hasOne(BookingGroup)
    }

    get customer() {
        return this.hasOne(Customer)
    }

    get service() {
        return this.services.length ? this.services[0] : null;
    }

    get nonScheduledBookings() {
        return this.hasMany(NonScheduledBooking);
    }

    static get typeOptions()
    {
        return [
            {
                label: 'Activity',
                value: 'activity',
            },
            {
                label: 'Accommodation',
                value: 'accommodation',
            },
        ]
    }

    static get originPlatformGuestApp() {
        return 'guest-app';
    };

    static get originPlatformBackoffice() {
        return 'back-office';
    };

    static get typeActivity() {
        return 'activity';
    };

    static get typeAccommodation() {
        return 'accommodation';
    };

    static get statusOptions() {
        return [
            // {
            //     label: 'Enquired',
            //     value: 'enquired',
            //     editable: false,
            //     color: 'default'
            // },
            // {
            //     label: 'Requested',
            //     value: 'requested',
            //     editable: false,
            //     color: 'default'
            // },
            {
                label: 'Optional',
                value: 'optional',
                editable: true,
                color: 'default'
            },
            {
                label: 'Confirmed',
                value: 'confirmed',
                editable: true,
                color: 'success'
            },
            {
                label: 'Started',
                value: 'started',
                editable: true,
                color: 'success'
            },
            {
                label: 'Finished',
                value: 'processed',
                editable: true,
                color: 'default'
            },
            {
                label: 'No show',
                value: 'no-show',
                editable: true,
                color: 'default'
            },
            {
                label: 'Canceled',
                value: 'canceled',
                editable: true,
                color: 'error'
            },
        ]
    }

    static get packageOptions()
    {
        return [
            {
                label: 'N/A',
                value: null,
            },
            {
                label: 'Evening meal package',
                value: 'evening-meal-package',
            },
        ]
    }

    static get formStatusOptions()
    {
        return [
            {
                label: 'Form not sent',
                value: 'form-not-sent',
            },
            {
                label: 'Form sent',
                value: 'form-sent',
            },
            {
                label: 'Form returned',
                value: 'form-returned',
            },
        ]
    }

    static get paymentTypeOptions()
    {
      return [
        {
          label: 'Stripe',
          value: 'stripe',
        },
        {
          label: 'Voucher',
          value: 'voucher',
        },
        {
          label: 'Stripe + Voucher',
          value: 'stripe_voucher',
        },
      ]
    }
}
