<template>
  <Dialog _class="w:540@md" v-model="open" :title="form.fields.id ? 'Update exception' : 'Create exception'" @submit="handleSave">
    <div class="flex:|center gap:16 mb:16">
      <div class="flex:1">
        <label class="--label mb:4">From</label>
        <CalendarInput v-model="form.fields.dateFrom" :allowClear="false" />
        <FormError field="date_from" :form="form" />
      </div>
      <div class="flex:1 pt:22">
        <b-timepicker v-model="form.fields.timeFrom" :disabled="allDay" :increment-minutes="15" />
        <FormError field="time_from" :form="form" />
      </div>
    </div>
    <div class="flex:|center gap:16 mb:16">
      <div class="flex:1">
        <label class="--label mb:4">To</label>
        <CalendarInput v-model="form.fields.dateTo" :allowClear="false" position="is-bottom-right" />
        <FormError field="date_to" :form="form" />
      </div>
      <div class="flex:1 pt:22">
        <b-timepicker v-model="form.fields.timeTo" :disabled="allDay" :increment-minutes="15" />
        <FormError field="time_to" :form="form" />
      </div>
    </div>
    <div class="flex:|center gap:16 mb:16">
      <div class="flex:1">
        <label>
          <input class="--checkbox" type="checkbox" v-model="specificWeekDays" @change="handleSpecificDaysToggle" />
          Specific week days
        </label>
      </div>
      <div class="flex:1">
        <label>
          <input class="--checkbox" type="checkbox" v-model="allDay" @change="handleAllDayToggle" />
          All day
        </label>
      </div>
    </div>
    <div v-if="specificWeekDays" class="flex:|center gap:16 mb:16">
      <div class="flex:1">
        <label class="mr:8" v-for="weekDay in weekDaysOptions">
          <input
            class="--checkbox" type="checkbox"
            v-model="form.fields.weekDays"
            :value="weekDay.value"
            :key="weekDay.value"/>
          {{ weekDay.label }}
        </label>
      </div>
    </div>
    <div class="flex:|center gap:16 mb:16">
      <div class="flex:1">
        <label class="--label mb:4">Description</label>
        <textarea v-model="form.fields.description" class="--textarea" />
      </div>
    </div>
  </Dialog>
</template>

<script>
import moment from 'moment-timezone'
import CalendarInput from '@/components/admin/CalendarInput'
import Dialog from '@/components/admin/Dialog'
import {Form} from "@/internal/modules";
import {Unavailability} from '@/internal';
import {mapActions} from "vuex";
import mixin from "@/mixin";

export default {
  name: 'UnavailabilityDialog',
  mixins: [mixin],
  components: {
    Dialog,
    CalendarInput,
  },
  props: {
  },
  computed: {
    timezone() {
      return this.$store.state.system.timezone;
    },
  },
  data() {
    return {
      open: false,
      loading: false,
      form: new Form({
        dateFrom: new Date(),
        dateTo: new Date(),
        timeFrom: moment('00:00', 'HH:mm').toDate(),
        timeTo: moment('23:45', 'HH:mm').toDate(),
        weekDays: [],
        description: null,
        entityId: null,
        entityType: null
      }),
      allDay: false,
      specificWeekDays: false,
      weekDaysOptions: Unavailability.weekDaysOptions,
    }
  },
  mounted() {
    let startAt = moment().utc().hours(0).minutes(0).tz(this.timezone);
    let endAt = moment().utc().hours(23).minutes(45).tz(this.timezone);

    this.form.fields = {
      ...this.form.fields,
      dateFrom: startAt.toDate(),
      dateTo: endAt.toDate(),
      timeFrom: startAt.toDate(),
      timeTo: endAt.toDate(),
    }
  },
  methods: {
    ...mapActions({
      unavailabilityStore: 'unavailability/store',
      unavailabilityUpdate: 'unavailability/update',
    }),
    handleAllDayToggle() {
      if (this.allDay) {
        this.form.fields.timeFrom =  moment('00:00', 'HH:mm').toDate();
        this.form.fields.timeTo =  moment('23:45', 'HH:mm').toDate();
      }
    },
    handleSpecificDaysToggle() {
      if (!this.specificWeekDays) {
        this.form.fields.weekDays = [];
      }
    },
    handleSave() {
      this.loading = true
      this.form.errors.clear()

      let startAt = moment(this.form.fields.dateFrom.setHours(this.form.fields.timeFrom.getHours(), this.form.fields.timeFrom.getMinutes()))
      let endAt = moment(this.form.fields.dateTo.setHours(this.form.fields.timeTo.getHours(), this.form.fields.timeTo.getMinutes()))

      const fields = {
        ...this.form.fields,
        startAt: this.toUtcTimezone(startAt.format('YYYY-MM-DD HH:mm')).format('YYYY-MM-DD HH:mm'),
        endAt: this.toUtcTimezone(endAt.format('YYYY-MM-DD HH:mm')).format('YYYY-MM-DD HH:mm'),
      }

      let action = null
      if (this.form.fields.id) {
        action = this.unavailabilityUpdate
      } else {
        action = this.unavailabilityStore
      }

      action(fields)
        .then((response) => {
          let id = Array.isArray(response) ? response[0] : response;
          this.clearForm()
          this.open = false
          this.$emit('success', id)
        })
        .catch((response) => {
          this.form.recordErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    clearForm() {
      this.form.fields = {
        dateFrom: new Date(),
        dateTo: new Date(),
        timeFrom: moment('00:00', 'HH:mm').toDate(),
        timeTo: moment('23:45', 'HH:mm').toDate(),
        weekDays: [],
        description: null,
        entityId: null,
        entityType: null
      }
      this.specificWeekDays = false;
    },
    setFormFields(fields) {
      this.form.fields = {
        ...fields,
        dateFrom: fields.startAt ? moment(fields.startAt).toDate() : new Date(),
        timeFrom: fields.startAt ? moment(fields.startAt).toDate() : moment('00:00', 'HH:mm').toDate(),
        dateTo: fields.endAt ? moment(fields.endAt).toDate() : new Date(),
        timeTo: fields.endAt ? moment(fields.endAt).toDate() : moment('23:45', 'HH:mm').toDate(),
        weekDays: fields.weekDays ? fields.weekDays : [],
        description: fields.description
      }
      this.specificWeekDays = !!(this.form.fields.weekDays && this.form.fields.weekDays.length > 0);
      this.allDay = moment(this.form.fields.timeFrom).format('HH:mm') === '00:00' &&
                    moment(this.form.fields.timeTo).format('HH:mm') === '23:45';
    }
  }
};
</script>

<style>
</style>
