<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <section class="section p-3">
            <div class="level">
                <div class="level-left">
                    <div class="fs-36 font--primary has-text-weight-bold">
                        Orders
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <div class="is-right">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section p-3">
          <div class="flex:between|center">
            <div class="new-input">
              <b-input placeholder="Search..."
                ref="search"
                type="search"
                icon="magnifying-glass"
                v-model="search"
                @input="handleSearch"
              />
            </div>

            <div class="flex:|center gap:8">
              <CalendarInput v-model="params.date"
                @input="handleDateChange"
                @clear="handleClearDate"
                :allow-clear="false"
              />

              <ComboInput
                v-model="selectedStatuses"
                @input="handleStatusChange"
                :options="statusOptions"
              />

              <b-dropdown aria-role="list" position="is-bottom-left">
                <template #trigger="{ active }">
                  <button class="reset flex:|center gap:4 f:16 h:40 px:8 r:8 b:base-20 o:0.6:hover">
                    <Icon admin name="arrow-up-down" />
                    Sort by
                  </button>
                </template>

                <b-dropdown-item class="flex" aria-role="listitem" @click="handleChangeSort('customer')">
                  <Icon admin name="users" class="mr-2"/><span>Customer</span>
                </b-dropdown-item>
                <b-dropdown-item class="flex" aria-role="listitem" @click="handleChangeSort('product')">
                  <Icon admin name="products" class="mr-2"/><span>Product</span>
                </b-dropdown-item>
                <b-dropdown-item class="flex" aria-role="listitem" @click="handleChangeSort('status')">
                  <Icon admin name="checklist" class="mr-2"/><span>Status</span>
                </b-dropdown-item>
              </b-dropdown>

              <b-dropdown aria-role="list" position="is-bottom-left">
                <template #trigger="{ active }">
                  <button class="reset flex:|center f:16 h:40 o:0.6:hover ml:-8">
                    <Icon admin name="ellipsis-vertical" />
                  </button>
                </template>

                <b-dropdown-item class="flex:|center gap:12" aria-role="listitem" @click="handleExport('csv')">
                  <Icon admin name="file-spreadsheet" /><span>Download CSV</span>
                </b-dropdown-item>
                <b-dropdown-item class="flex:|center gap:12" aria-role="listitem" @click="handleExport('pdf')">
                  <Icon admin name="file-text" /><span>Download PDF</span>
                </b-dropdown-item>
              </b-dropdown>

            </div>

          </div>
        </section>

        <section class="section p-3" style="height: calc(100vh - 202px);">

          <div class="card p-4 border--light-grey" style="height: 100%">
            <div class="card-content p-0 is-flex is-flex-direction-column is-justify-content-space-between" style="height: 100%;">
              <template v-if="data.length">
                <div class="columns" style="height: calc(100% - 52px); overflow-y: auto;">
                  <div class="column">
                                <b-table
                                  sticky-header
                                  height="calc(100vh - 324px)"
                                    :data="data"
                                    :striped="true"
                                    :hoverable="true"
                                    :sortable="true"
                                    @click="handleEdit"
                                  >

                                    <b-table-column field="deliveryAt" label="Date" v-slot="props">
                                        {{ dateFormat(props.row.deliveryAt) }}
                                    </b-table-column>

                                    <b-table-column field="product" label="Product" v-slot="props">
                                        {{ props.row.productNameQuantity }}
                                    </b-table-column>

                                    <b-table-column field="customer" label="Customer" v-slot="props">
                                        {{ props.row.customerName }}
                                    </b-table-column>

                                  <b-table-column field="origin" label="Origin" v-slot="props">
                                    {{ props.row.originPlatformDescription }}
                                  </b-table-column>

                                    <b-table-column field="note" label="Notes" v-slot="props">
                                        {{ props.row.note }}
                                    </b-table-column>

                                    <b-table-column field="status" label="Status" v-slot="props">

                                      <OrderStatusUpdate :ref="`statusRow${props.row.id}`"
                                                           :options="editableStatusOptions"
                                                           :id="props.row.id"
                                                           :status="props.row.status"
                                                           @update="handleStatusUpdate(props.row, $event)"
                                      />
                                    </b-table-column>

                                    <b-table-column field="action" label="" v-slot="props">
                                        <div class="is-flex">
                                            <div style="min-width:50px">
                                                <Icon v-if="props.row.checkoutType === 'paid'" admin name="booking-paid" title="Paid" />
                                                <Icon v-if="props.row.checkoutType === 'add-to-bill'" admin name="booking-add-to-bill" title="Added to bill"/>
                                            </div>
                                            <b-dropdown :ref="`contextRow${props.row.id}`"
                                                        aria-role="list"
                                                        class="is-clickable"
                                                        position="is-bottom-left">
                                                <template #trigger="{ active }">
                                                    <div class="px-2" @click.prevent.stop="handleContextActionClick(props.row)">
                                                        <i class="fa-solid fa-ellipsis-vertical"></i>
                                                    </div>
                                                </template>

                                                <b-dropdownItem aria-role="listitem" >
                                                    <div @click.prevent.stop="handleEdit(props.row)">
                                                        View
                                                    </div>
                                                </b-dropdownItem>
                                            </b-dropdown>
                                        </div>
                                    </b-table-column>

                                </b-table>
                            </div>
                        </div>
                        <div v-if="paginator"  class="columns">
                            <div class="column">
                                <Paginator :paginator="paginator"></Paginator>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <section class="hero">
                            <div class="hero-body">
                                <p class="has-text-centered">
                                    No orders found for selected date
                                </p>
                            </div>
                        </section>
                    </template>
                </div>
            </div>

        </section>

        <OrderUpdateModal v-if="$route.query.show" :id="$route.query.show" @close="$router.back()" />

    </div>
</template>

<script>

import {mapActions} from 'vuex';
import Paginator from '@/views/Admin/components/Paginator';
import CalendarInput from "@/components/admin/CalendarInput";
import OrderStatusUpdate from "@/components/admin/OrderStatusUpdate";
import ComboInput from '@/views/Admin/components/ComboInput';
import {debounce} from "lodash";
import moment from "moment-timezone";
import mixin from "@/mixin";
import _ from 'lodash';
import OrderUpdateModal from '@/components/admin/Order/OrderUpdateModal';

import {
    Order,
} from '@/internal'

export default {
    name: 'Index',
    mixins: [mixin],
    components: {
        Paginator,
        CalendarInput,
        ComboInput,
        OrderUpdateModal,
        OrderStatusUpdate
    },
    computed: {
      timezone() {
        return this.$store.state.system.timezone;
      },
      data() {
        return this.ids ? this.$store.getters['order/collection'](this.ids) : [];
      },
      fullPath () {
        return this.$route.fullPath;
      },
      selectedStatusString() {
          if(this.params.status.length > 2) {
            const arr = this.params.status;
            return arr.slice(0,2).join(",") + "...";
          } else if(this.params.status.length > 0 && this.params.status.length <= 2) {
            return this.params.status.join(",");
          } else {
            return "Any status";
          }
      },
      editableStatusOptions() {
          return this.statusOptions.filter(status => status.editable);
      },
      isModalOpen() {
        return this.$route.query.show;
      }
    },
    created() {
        this.loadData(true);
        this.createAutoloadInterval();
        this.prefillSelectedStatuses()
    },
    beforeDestroy() {
      clearInterval(this.autoloadIntervalId);
    },
    data() {
        return {
            ids: [],
            paginator: null,
            search: this.$route.query.search || null,
            performSearch: debounce(() => {
                if(this.search && this.search !== "") {
                    this.updateQuery('search', this.search);
                } else {
                    this.updateQuery('search', null);
                }
            }, 1000),
            statusOptions: Order.statusOptions,
            loading: false,
            params: {
                date: this.$route.query.date ? moment(this.$route.query.date).toDate() : new Date(),
                search: this.$route.query.search || null,
                status: this.$route.query.status ? this.$route.query.status.split(",") : ['requested','confirmed']
            },
            selectedStatuses: [],
            autoloadIntervalId: null
        }
    },
    methods: {
        ...mapActions({
            orderIndex: 'order/index',
            orderDestroy: 'order/destroy',
            orderStatusUpdate: 'order/statusUpdate'
        }),
        createAutoloadInterval() {
          this.autoloadIntervalId = setInterval(() => {
            if (!this.isModalOpen) {
              this.loadData(false, true);
            }
          }, 60000); // 1 minute
        },
        prefillSelectedStatuses() {
          this.params.status.forEach(s => {
            this.selectedStatuses.push(this.statusOptions.find(status => status.value === s))
          })
        },
        formatDatepickerDate(date) {
            return date ? date.toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) : ''
        },
        dateFormat(value) {
            return this.dateFormatTimezone(value).format('ddd, D MMM YYYY');
        },
        timeFormat(value) {
            return this.dateFormatTimezone(value).format('hh:mm A');
        },
        handleClearDate() {
            this.updateQuery('date', null);
            this.params.date = null;
        },
        handleEdit(row) {
          this.updateQuery('show', row.id);
        },
        handleChangeSort (sortBy) {
          this.updateQuery('sortBy', sortBy);
        },
        handleSearch() {
            this.performSearch();
        },
        handleDateChange() {
            this.updateQuery('date', moment(this.params.date).format("YYYY-MM-DD"))
        },
        handleStatusChange(status) {
          this.params.status = status.map(s => s.value)
          this.updateQuery('status', status.length ? status.map(s => s.value).join(",") : null)
        },
        updateQuery(key, value) {
            const route = Object.assign({}, this.$route);

            const query = {
                ...route.query,
            };

            if(value) {
                query[key] = value;
            } else {
                delete query[key];
            }


            this.$router.push({
                name: route.name,
                query
            });
        },
        handleStatusActionClick(row) {
            this.$refs['statusRow' + row.id].toggle();
        },
        handleContextActionClick(row) {
            this.$refs['contextRow' + row.id].toggle();
        },
        handleRowActionUpdate(row) {
            const route = window.app.findRouteByName('admin/order/update', [], {
                params: {
                    orderId: row.id
                },
            });

            this.$router.push(route);
        },
        handleStatusUpdate(row, statusOption) {
            if(row.status === statusOption.value) {
                return;
            }

            window.app.confirm({
                title: 'Change status',
                message: `Are you sure you want to change order status to ${statusOption.label}?`,
                type: 'is-info',
                onConfirm: () => {
                    this.loading = true;

                    this.orderStatusUpdate({
                        id: row.id,
                        status: statusOption.value
                    }).then(() => {
                        window.app.snackbar('Order status updates');
                        this.loadData();
                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });

                },
            });
        },
        handleRowActionDelete(row) {
            window.app.confirm({
                title: 'Delete Order',
                message: 'Are you sure you want to delete this order?',
                onConfirm: () => {
                    this.loading = true;

                    this.orderDestroy({id: row.id}).then(() => {
                        window.app.snackbar('Order deleted');
                        this.loadData();
                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });

                },
            });
        },
        loadData(initial = false, workInBackground = false) {
            if (!workInBackground) {
              this.loading = true;
            }

            const params = {
                page: this.$route.query.page || 1,
                perPage: this.$route.query.perPage || 100,
                sortBy: this.$route.query.sortBy || 'created_at',
                sortOrder: this.$route.query.sortOrder || 'asc',
                show: this.$route.query.show || null,
            };

            if (this.search) {
                params.search = this.search;
            }

            if(this.params.date) {
                params.date = moment(this.params.date).format("YYYY-MM-DD");
            }

            if(this.params.status.length > 0) {
                params.status = this.params.status.join(",");
            }

            // First load
            if(initial) {
                // Workaround: If query is the same router will not detect a change on first load
                if(_.isEqual(this.$route.query, params)) {
                    this.loadData();
                }

                const route = Object.assign({}, this.$route);

                this.$router.push({
                    name: route.name,
                    query: {
                        ...params,
                    }
                });

                return;
            }

            this.orderIndex({
                params,
            }).then((response) => {
                if (response.paginator) {
                    this.paginator = response.paginator;
                }

                this.ids = response.ids;
            }).finally(() => {
                this.loading = false;
            })
        },
        handleExport(type) {
          this.loading = true;

          const params = {
            page: this.$route.query.page || 1,
            perPage: this.$route.query.perPage || 100,
            sortBy: this.$route.query.sortBy || 'created_at',
            sortOrder: this.$route.query.sortOrder || 'asc',
          };

          if (this.search) {
            params.search = this.search;
          }

          if(this.params.date) {
            params.date = moment(this.params.date).format("YYYY-MM-DD");
          }

          if(this.params.status.length > 0) {
            params.status = this.params.status.join(",");
          }

          fetch('/api/order?' + new URLSearchParams({
            ...params,
            export: type
          }), {
            headers: {
              'X-XSRF-TOKEN': decodeURIComponent(document.cookie.split('; ').find(row => row.startsWith('XSRF-TOKEN=')).split('=')[1]),
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            credentials: 'include'
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.blob();
          })
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Orders.' + type);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
          }).finally(() => {
          this.loading = false;
        })
      },
    },
    watch: {
        fullPath () {
            this.loadData();
        },
    },
}
</script>
