<template>
  <div>
    <b-loading :is-full-page="true" v-model="loading"></b-loading>
    <div v-if="service">
      <div class="p:24">
        <div class="b:base-20 r:12 mb:24">
          <div class="flex:between|center p:24|12">
            <div class="f:24 c:accent-70 semi">Working hours</div>
            <button
              @click="$refs.workingHoursDialog.open = true"
              class="reset flex:|center gap:8 f:16 semi p:8|12 r:8 o:0.9:hover bg:primary c:white"
            >
              <Icon admin name="plus" />
              Add New
            </button>
          </div>
          <b-table v-if="workingHours.length" :data="workingHours" :striped="true" :hoverable="true" :sortable="true">
            <b-table-column field="dayOfTheWeek" label="Day Of The Week" v-slot="props">
              {{ dayOfWeekMapper(props.row.weekDay) }}
            </b-table-column>

            <b-table-column field="from" label="Hours" v-slot="props">
              {{ convertTime(props.row.from).format('HH:mm') }} -
              {{ convertTime(props.row.to).format('HH:mm') }}
            </b-table-column>

            <b-table-column label="" v-slot="props">
              <b-dropdown
                :ref="`contextRow${props.row.id}`"
                aria-role="list"
                position="is-bottom-left"
                class="has-text-left is-clickable"
              >
                <template #trigger="{ active }">
                  <div @click.prevent.stop="handleRowActionClick(props.row)">
                    <BIcon icon="ellipsis" />
                  </div>
                </template>

                <b-dropdownItem aria-role="listitem" class="pr-0">
                  <div @click.prevent.stop="handleUpdate(props.row)">
                    <BIcon icon="pencil-alt" />
                    Update
                  </div>
                </b-dropdownItem>

                <b-dropdownItem aria-role="listitem" class="pr-0">
                  <div @click.prevent.stop="handleDelete(props.row)">
                    <BIcon icon="trash-alt" />
                    Delete
                  </div>
                </b-dropdownItem>
              </b-dropdown>
            </b-table-column>
          </b-table>
          <div v-else class="flex:center p:24">
            <div class="f:16 c:accent-70">No working hours added</div>
          </div>
        </div>

        <div class="b:base-20 r:12">
          <div class="flex:between|center p:24|12">
            <div class="f:24 c:accent-70 semi">Closed days</div>
            <button
              @click="$refs.closedDaysDialog.open = true"
              class="reset flex:|center gap:8 f:16 semi p:8|12 r:8 o:0.9:hover bg:primary c:white"
            >
              <Icon admin name="plus" />
              Add New
            </button>
          </div>
          <b-table
            v-if="closedHours && closedHours.length > 0"
            :data="closedHours"
            :striped="true"
            :hoverable="true"
            :sortable="true"
          >
            <b-table-column label="Closed days" v-slot="props">
              <span v-if="props.row.startDate === props.row.endDate">
                {{ formatDate(props.row.startDate) }}
              </span>
              <span v-else>{{ formatDate(props.row.startDate) }} - {{ formatDate(props.row.endDate) }}</span>
            </b-table-column>

            <b-table-column field="from" label="Hours" v-slot="props">
              <span v-if="isAllDay(props.row.startTime, props.row.endTime)">All day</span>
              <span v-else>
                {{ formatHours(props.row.startTime) }} -
                {{ formatHours(props.row.endTime) }}
              </span>
            </b-table-column>

            <b-table-column label="" v-slot="props">
              <b-dropdown
                :ref="`contextRow${props.row.id}`"
                aria-role="list"
                position="is-bottom-left"
                class="has-text-left is-clickable"
              >
                <template #trigger="{ active }">
                  <div @click.prevent.stop="handleRowActionClick(props.row)">
                    <BIcon icon="ellipsis" />
                  </div>
                </template>

                <b-dropdownItem aria-role="listitem" class="pr-0">
                  <div @click.prevent.stop="handleUpdateClosedHour(props.row)">
                    <BIcon icon="pencil-alt" />
                    Update
                  </div>
                </b-dropdownItem>

                <b-dropdownItem aria-role="listitem" class="pr-0">
                  <div @click.prevent.stop="handleDeleteClosedHour(props.row)">
                    <BIcon icon="trash-alt" />
                    Delete
                  </div>
                </b-dropdownItem>
              </b-dropdown>
            </b-table-column>
          </b-table>
          <div v-else class="flex:center p:24">
            <div class="f:16 c:accent-70">No closed hours</div>
          </div>
        </div>
      </div>

      <!-- <b-modal
        v-model="closedHoursModalActive"
        trap-focus
        :destroy-on-hide="true"
        :can-cancel="false"
        aria-role="dialog"
        aria-label="Closed Days Modal"
        aria-modal
      >
        <template #default="props">
          <ClosedHoursModal
            :closedHour="activeClosedHour"
            :service="service"
            @success="handleClosedHoursSuccess"
            @close="props.close"
          />
        </template>
      </b-modal> -->

      <WorkingHoursDialog ref="workingHoursDialog" @onSave="loadService" />
      <ClosedDaysDialog ref="closedDaysDialog" @onSave="handleClosedHoursSuccess" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Form } from '@/internal/modules'
import ServiceWorkingHour from '@/domain/ServiceWorkingHour/ServiceWorkingHour'

import FormMenu from '@/views/Admin/Service/FormMenu.vue'
import Paginator from '@/views/Admin/components/Paginator.vue'
import ClosedHoursModal from '@/views/Admin/components/ClosedHoursModal.vue'
import WorkingHoursDialog from './WorkingHoursDialog.vue'
import ClosedDaysDialog from './ClosedDaysDialog.vue'

import moment from 'moment-timezone'

export default {
  name: 'WorkingHours',
  components: {
    FormMenu,
    Paginator,
    ServiceWorkingHour,
    ClosedHoursModal,
    WorkingHoursDialog,
    ClosedDaysDialog,
  },
  computed: {
    service() {
      return this.serviceId ? this.$store.getters['service/show'](this.serviceId) : null
    },
    timezone() {
      return this.$store.state.system.timezone
    },
    workingHours() {
      return this.service ? this.service.workingHours : []
    },
    closedHours() {
      return this.service ? this.service.closedHours : []
    },
  },
  data() {
    return {
      form: new Form({
        weekDay: null,
        from: null,
        to: null,
        service_id: this.$route.query.show,
      }),
      daysOfWeek: [
        { label: 'Monday', value: 1 },
        { label: 'Tuesday', value: 2 },
        { label: 'Wednesday', value: 3 },
        { label: 'Thursday', value: 4 },
        { label: 'Friday', value: 5 },
        { label: 'Saturday', value: 6 },
        { label: 'Sunday', value: 7 },
      ],
      paginator: null,
      loading: false,
      serviceId: null,
      workingHoursDialog: null,
      closedDaysDialog: null,
      closedHoursModalActive: false,
      activeClosedHour: null,
    }
  },
  mounted() {
    this.loadService()
  },
  methods: {
    ...mapActions({
      serviceShow: 'service/show',
      workingHoursDestroy: 'serviceWorkingHour/destroy',
      workingHoursStore: 'serviceWorkingHour/store',
      workingHoursUpdate: 'serviceWorkingHour/update',
      closedHoursDestroy: 'serviceClosedHour/destroy',
    }),
    dayOfWeekMapper(value) {
      return this.daysOfWeek.find((day) => day.value === value).label
    },
    handleRowActionClick(row) {
      this.$refs['contextRow' + row.id].toggle()
    },
    convertTime(time) {
      // Convert from UTC to local timezone
      return moment.utc('2023-07-22 ' + time).tz(this.timezone)
    },
    formatHours(time) {
      return moment.utc('2023-07-22 ' + time).format('HH:mm')
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    handleUpdate(row) {
      this.$refs.workingHoursDialog.setFormFields({
        ...row,
        from: new Date(`2023-07-22 ${this.convertTime(row.from).format('HH:mm')}`),
        to: new Date(`2023-07-22 ${this.convertTime(row.to).format('HH:mm')}`),
      })
      this.$refs.workingHoursDialog.open = true
    },
    handleUpdateClosedHour(row) {
      this.$refs.closedDaysDialog.setFormFields(row)
      this.$refs.closedDaysDialog.open = true
    },
    handleClosedHoursSuccess() {
      this.loadService()
    },

    handleDelete(row) {
      window.app.confirm({
        title: 'Delete Working Hours',
        message: 'Are you sure you want to delete this working hours?',
        onConfirm: () => {
          this.loading = true

          this.workingHoursDestroy({ id: row.id })
            .then(() => {
              window.app.snackbar('Service working hours deleted')
              this.loadService()
            })
            .catch((e) => {
              if (e.response && e.response.data) {
                window.app.snackbar({
                  message: e.response.data.message,
                  type: 'is-danger',
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    },
    handleDeleteClosedHour(row) {
      window.app.confirm({
        title: 'Delete Closed Days',
        message: 'Are you sure you want to delete this closed days?',
        onConfirm: () => {
          this.loading = true

          this.closedHoursDestroy({ id: row.id })
            .then(() => {
              window.app.snackbar('Closed days deleted')
              this.loadService()
            })
            .catch((e) => {
              if (e.response && e.response.data) {
                window.app.snackbar({
                  message: e.response.data.message,
                  type: 'is-danger',
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    },
    loadService() {
      this.loading = true

      return this.serviceShow({
        id: this.$route.query.show,
        params: {
          include: ['serviceWorkingHours', 'serviceClosedHours'],
        },
      })
        .then((response) => {
          this.serviceId = response[0]
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false
        })
    },
    isAllDay(startTime, endTime) {
      return startTime === '00:00:00' && endTime === '23:45:00'
    },
  },
  watch: {
    workingHoursModal(isOpen) {
      if (!isOpen) {
        this.form.fields = {
          weekDay: null,
          from: null,
          to: null,
          service_id: this.$route.query.show,
        }
      }
    },
  },
}
</script>
