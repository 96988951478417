<template>
  <div class="rel">
    <b-loading v-model="loading" :is-full-page="false" />
    <div class="flex-col gap:24 mb:40 flex@md">
      <div class="flex:1">
        <div class="flex-col gap:12">
          <label class="--label">
            First name*
            <input type="text" v-model="form.fields.firstName" class="--input mt:4" />
            <form-error field="first_name" :form="form" />
          </label>
          <label class="--label">
            Last name*
            <input type="text" v-model="form.fields.lastName" class="--input mt:4" />
            <form-error field="last_name" :form="form" />
          </label>
          <label class="--label">
            Email
            <input type="text" v-model="form.fields.email" class="--input mt:4" />
            <form-error field="email" :form="form" />
          </label>
          <label class="--label flex-col gap:4">
            Phone
            <MazPhoneNumberInput
              v-model="phoneNumber"
              @update="handlePhoneNumberUpdate"
              :default-phone-number="defaultPhone"
              :default-country-code="defaultCountryCode"
            />
          </label>
        </div>
      </div>
      <div v-if="customer" class="flex:1 p:8|24 b:base-20 r:8">
        <template v-if="activeBooking && customerActiveBookingId">
          <div class="py:16 bb:base-20">
            <div class="c:base-50 f:16 semi flex gap:8">
              <Icon admin name="book" />
              Active booking
            </div>
            <div class="c:base-70 f:16 medium">#{{ activeBooking.number }}</div>
          </div>
          <div class="py:16 bb:base-20 flex gap:24">
            <div class="flex:1">
              <div class="c:base-50 f:16 semi flex gap:8">
                <Icon admin name="bed" />
                Room
              </div>
              <div class="c:base-70 f:16 medium">
                {{ activeBooking.category && activeBooking.category.name }}
              </div>
            </div>
            <div class="flex:1">
              <div class="c:base-50 f:16 semi flex gap:8">
                <Icon admin name="user-group" />
                Guests
              </div>
              <div class="c:base-70 f:16 medium">{{ activeBooking.totalPersons }} Guests</div>
            </div>
          </div>
          <div class="py:16 flex gap:24">
            <div class="flex:1">
              <div class="c:base-50 f:16 semi flex gap:8">
                <Icon admin name="calendar-in" />
                Check in
              </div>
              <div class="c:base-70 f:16 medium">
                {{ activeBooking.startAt.format('MMM Do, YYYY') }}
              </div>
            </div>
            <div class="flex:1">
              <div class="c:base-50 f:16 semi flex gap:8">
                <Icon admin name="calendar-out" />
                Check out
              </div>
              <div class="c:base-70 f:16 medium">
                {{ activeBooking.endAt.format('MMM Do, YYYY') }}
              </div>
            </div>
          </div>
          <div class="flex-inline">
            <div class="flex:|center gap:8 f:14 c:primary-80 bg:primary-10 p:4|8 r:4 semi">
              <Icon admin name="moon" />
              <div>{{ activeBooking.nightsCount }} night{{ activeBooking.nightsCount > 1 ? 's' : '' }}</div>
            </div>
          </div>
        </template>
        <div v-else-if="!customerActiveBookingId" class="flex:center|center h:full">
          <div class="flex-col:|center gap:12">
            <Icon admin name="book-x" class="c:base-50" />
            <div class="f:16 semi c:base-50">No active booking</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="customer">
      <div class="mb:32">
          <div class="flex:between|center">
            <div class="section-title">
              <Icon admin name="user-group" />
              <div>Associated customers</div>
            </div>
            <button
              @click="$refs.addAssociatedCustomerDialog.open = true"
              class="reset flex:|center gap:8 f:16 semi c:accent py:12 o:0.9:hover"
            >
              <Icon admin name="plus-circle" />
              Add new
            </button>
          </div>
          <table class="styled striped of:visible!" v-if="associatedCustomers.length">
            <tr v-for="customer in associatedCustomers">
              <td>{{ customer.firstName }} {{ customer.lastName }}</td>
              <td>{{ customer.email }}</td>
              <td>{{ customer.phoneE164 }}</td>
              <td>
                <b-dropdown :ref="`associatedCustomerContext${customer.id}`"
                            aria-role="list"
                            class="is-clickable"
                            position="is-bottom-left">
                  <template #trigger="{ active }">
                    <div class="px-2" @click.prevent.stop="associatedCustomerContextClick(customer)">
                      <i class="fa-solid fa-ellipsis-vertical"></i>
                    </div>
                  </template>

                  <b-dropdownItem aria-role="listitem" >
                    <div @click.prevent.stop="removeAssociatedCustomer(customer)">
                      Remove
                    </div>
                  </b-dropdownItem>
                </b-dropdown>
              </td>
            </tr>
          </table>
          <div v-else class="p:20 flex:|center gap:12 b:base-20 r:8">
            <Icon admin name="x" class="c:error" />
            No associated customers
          </div>
        </div>
      <div class="mb:32">
        <div class="section-title">
          <Icon admin name="ticket-clock" />
          <div>Pending bookings</div>
        </div>
        <table class="styled striped" v-if="nonScheduledBookings.length">
          <tr v-for="booking in nonScheduledBookings" class="flex:|center flex:1">
            <td>#{{ booking.id }}</td>
            <td class="w:full">{{ booking.serviceName }}</td>
            <td>
              <div v-if="booking.status === nonScheduledBookingModel.statusPending">
                <div class="flex:|center gap:4 c:primary-80 f:14 medium bg:primary-10 p:4|12 r:40 mr:24 capitalize">
                  <Icon admin name="clock" />
                  {{ booking.status }}
                </div>
              </div>
              <div v-if="booking.status === nonScheduledBookingModel.statusBooked">
                <div class="flex:|center gap:4 c:primary-80 f:14 medium bg:success-10 p:4|12 r:40 mr:24 capitalize">
                  <Icon admin name="check" />
                  {{ booking.status }}
                </div>
              </div>
            </td>
            <td>
              <template v-if="booking.scheduledBookingId">
                <router-link
                  class="flex:|center gap:4 c:primary f:16 semi b:base-20 p:10|12 r:8"
                  :to="`/admin/booking?edit=${booking.scheduledBookingId}`"
                >
                  Manage
                </router-link>
              </template>
              <template v-else>
                <button
                  class="reset flex:|center gap:4 c:primary f:16 semi b:base-20 p:10|12 r:8"
                  @click="handleSchedulePendingBooking(booking)"
                >
                  Schedule
                </button>
              </template>
            </td>
          </tr>
        </table>
        <div v-else class="p:20 flex:|center gap:12 b:base-20 r:8">
          <Icon admin name="x" class="c:error" />
          No pending bookings
        </div>
      </div>
      <div class="mb:32">
        <div class="flex:between|center">
          <div class="section-title">
            <Icon admin name="ticket" />
            <div>Activity booking</div>
          </div>
          <button
            @click="handleCreateNewBooking()"
            class="reset flex:|center gap:8 f:16 semi c:accent py:12 o:0.9:hover"
          >
            <Icon admin name="plus-circle" />
            Add new
          </button>
        </div>
        <table class="styled striped" v-if="activityBookings.length">
          <tr v-for="booking in activityBookings">
            <td>#{{ booking.number }}</td>
            <td>{{ booking.service && booking.service.name }}</td>
            <td>
              <div class="flex:|center gap:8">
                <Icon admin name="calendar" />
                <div>
                  {{ booking.startAt.format('dddd, DD/MM/YYYY') }}
                  <div class="c:base-50 f:14">{{ timeFormat(booking.startAt) }} - {{ timeFormat(booking.endAt) }}</div>
                </div>
              </div>
            </td>
            <td v-if="group">
              {{ booking.customerName }}
            </td>
            <td>
              <Status :status="booking.status" />
            </td>
            <td>
              <button
                class="reset flex:|center gap:4 c:primary f:16 semi b:base-20 p:10|12 r:8"
                @click="handleBookingManage(booking)"
              >
                Manage
              </button>
            </td>
          </tr>
        </table>
        <div v-else class="p:20 flex:|center gap:12 b:base-20 r:8">
          <Icon admin name="x" class="c:error" />
          No activity bookings
        </div>
      </div>
      <div class="mb:32">
        <div class="section-title">
          <Icon admin name="bed" />
          <div>Accommodation booking</div>
        </div>
        <table class="styled striped" v-if="accommodationBookings.length">
          <tr v-for="booking in accommodationBookings">
            <td>#{{ booking.number }}</td>
            <td>
              {{ booking.service && booking.service.name }}
              <div v-if="booking.category" class="regular mb:4">({{ booking.category.name }})</div>
            </td>
            <td>
              <div class="flex:|center gap:4">
                <Icon admin name="user-group" />
                {{ booking.totalPersons }}
              </div>
            </td>
            <td>
              <div class="flex:|center gap:4">
                <Icon admin name="calendar" />
                {{ booking.startAt.format('dddd DD/MM/YY') }}
                <br />
                {{ booking.endAt.format('dddd DD/MM/YY') }}
              </div>
            </td>
            <td>
              <Status :status="booking.status" />
            </td>
            <td>
              <button
                class="reset flex:|center gap:4 c:primary f:16 semi b:base-20 p:10|12 r:8"
                @click="handleBookingManage(booking)"
              >
                Manage
              </button>
            </td>
          </tr>
        </table>
        <div v-else class="p:20 flex:|center gap:12 b:base-20 r:8">
          <Icon admin name="x" class="c:error" />
          No accommodation booking
        </div>
      </div>

      <div class="mb:32">
        <div class="section-title">
          <Icon admin name="room-service" />
          <div>Orders</div>
        </div>
        <table class="styled striped" v-if="orders.length">
          <tr v-for="order in orders">
            <td>#{{ order.reference }}</td>
            <td>
              <template v-for="orderProduct in order.orderProducts">
                {{ orderProduct.productName }} x {{ orderProduct.quantity }}
              </template>
            </td>
            <td>£ {{ order.price }}</td>
            <td>{{ order.createdAt.format('dddd, DD/MM/YYYY') }} {{ timeFormat(order.createdAt) }}</td>
            <td v-if="group">
              {{ order.customerName }}
            </td>
            <td>
              <div
                class="flex-inline:center|center gap:4 c:success-70 f:14 medium bg:success-10 p:4|12 r:40 capitalize"
              >
                <Icon admin name="check" />
                {{ order.status }}
              </div>
            </td>
          </tr>
        </table>
        <div v-else class="p:20 flex:|center gap:12 b:base-20 r:8">
          <Icon admin name="x" class="c:error" />
          No orders
        </div>
      </div>

      <AddAssociatedCustomerDialog
        v-if="customer"
        :customerId="customer.id"
        :groupId="group ? group.id : null"
        :booking="activeBooking"
        @success="loadDataAfterGroupUpdate"
        ref="addAssociatedCustomerDialog"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { MazPhoneNumberInput } from 'maz-ui'
import mixin from '@/mixin'
import { Form } from '@/internal/modules'
import { NonScheduledBooking } from '@/internal'
import SideDrawer from '@/components/admin/SideDrawer'
import Status from '@/components/admin/Status'
import Vue from 'vue'
import moment from "moment";

import AddAssociatedCustomerDialog from '@/components/admin/Customer/AddAssociatedCustomerDialog.vue'

export default {
  name: 'OvervewTab',
  mixins: [mixin],
  components: {
    MazPhoneNumberInput,
    SideDrawer,
    Status,
    AddAssociatedCustomerDialog,
  },
  data() {
    return {
      addAssociatedCustomerDialog: false,
      form: new Form({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      }),
      bookingIds: [],
      orderIds: [],
      activityLogIds: [],
      phoneNumber: null,
      loading: false,
      customerId: null,
      serviceIds: [],
      activeBookingId: null,
      activeTab: 'overview',
      nonScheduledBookingModel: NonScheduledBooking,
    }
  },
  computed: {
    bookings() {
      return this.bookingIds.length ? this.$store.getters['booking/collection'](this.bookingIds) : []
    },
    activityBookings() {
      return this.bookings
        .filter((booking) => {
          return booking.type === 'activity'
        })
        .sort((a, b) => {
          return new Date(a.startAt) - new Date(b.startAt)
        })
    },
    accommodationBookings() {
      return this.bookings.filter((booking) => {
        return booking.type === 'accommodation'
      })
    },
    orders() {
      return this.orderIds.length ? this.$store.getters['order/collection'](this.orderIds) : []
    },
    activeBooking() {
      return this.activeBookingId ? this.$store.getters['booking/show'](this.activeBookingId) : null
    },
    nonScheduledBookings() {
      return this.activeBooking ? this.activeBooking.nonScheduledBookings : []
    },
    group() {
      if (this.customer && this.customer.groups) {
          return this.customer.groups.find((group) => {
              return moment().utc().isSameOrBefore(group.endAt, 'day');
          });
      }

      return null;
    },
    associatedCustomers() {
      return this.group ? this.group.customers.filter((customer) => {
            return parseInt(customer.id) !== parseInt(this.customerId)
          }) : []
    },
    defaultPhone() {
      return this.customer ? this.customer.phone : ''
    },
    customer() {
      return this.customerId ? this.$store.getters['customer/findBy'](this.customerId) : null
    },
    countries() {
      return this.$store.getters['country/all']
    },
    defaultCountryCode() {
      if (this.customer) {
        const code = this.countries.find((country) => {
          const code = country.dialCode.substring(1)
          if (country.preferred) {
            return this.customer.phoneCountryCode === code && country.preferred
          } else {
            return this.customer.phoneCountryCode === code
          }
        })
        return code ? code.code : 'US'
      } else {
        return 'US'
      }
    },
    user() {
      return this.$store.getters['auth/user']
    },
    services() {
      return this.serviceIds ? this.$store.getters['service/collection'](this.serviceIds) : []
    },
    customerActiveBookingId() {
      if (this.customer?.activeBookingId) {
        return this.customer.activeBookingId
      } else if (this.customer?.user && this.customer.user.activeBookingId) {
        return this.customer.user.activeBookingId
      } else {
        return null
      }
    },
    isEdit() {
      return this.$route.query.show !== 'create'
    },
    id() {
      return this.isEdit ? this.$route.query.show : null
    },
  },
  async mounted() {
    this.customerId = this.id
    if (this.customerId) {
      this.loading = true
      await this.loadCustomer()
      this.loadBookings()
      this.loadOrders()
      this.fillForm()
    }

    if (this.customerActiveBookingId) {
      this.loadActiveBooking()
      this.loadServices()
    }
  },
  methods: {
    ...mapActions({
      customerDestroy: 'customer/destroy',
      customerStore: 'customer/store',
      customerUpdate: 'customer/update',
      customerShow: 'customer/show',
      customerExportData: 'customer/exportData',
      bookingIndex: 'booking/index',
      bookingShow: 'booking/show',
      serviceIndex: 'service/index',
      userLoginUrl: 'user/loginUrl',
      orderIndex: 'order/index',
      groupCustomerRemove: 'group/customerRemove'
    }),
    timeFormat(value) {
      return this.dateFormatTimezone(value).format('hh:mm A')
    },
    handleSave() {
      this.loading = true
      this.form.errors.clear()

      let action = null

      if (this.customer) {
        action = this.customerUpdate
      } else {
        action = this.customerStore
      }

      action({
        ...this.form.fields,
      })
        .then(() => {
          window.app.snackbar({
            message: 'Saved successfully!',
          })
          this.$emit('close')
        })
        .catch((error) => {
          this.form.recordErrors(error)
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleDelete() {
      window.app.confirm({
        title: 'Delete Customer',
        message: 'Are you sure you want to delete this customer?',
        onConfirm: () => {
          this.loading = true
          this.customerDestroy({
            id: this.customer.id,
          })
            .then(() => {
              window.app.snackbar('Customer Deleted')
              this.$router.push('/admin/customer')
              this.$emit('close')
            })
            .catch((e) => {
              if (e.response && e.response.data) {
                window.app.snackbar({
                  message: e.response.data.message,
                  type: 'is-danger',
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    },
    handleExportData() {
      this.loading = false

      return this.customerExportData(this.customer.id)
        .then((data) => {
          let fileName = this.customer.id + '_data.json'
          let fileURL = window.URL.createObjectURL(new Blob([JSON.stringify(data)]))
          let fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', fileName)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch((error) => {
          window.app.snackbar({
            message: error.response.data.message,
            type: 'is-danger',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    handlePhoneNumberUpdate(event) {
      if (!event.isValid) {
        return
      }
      this.form.fields.phone = event.phoneNumber
      this.form.fields.phoneCountryCode = event.countryCallingCode
    },
    async loadCustomer() {
      return this.customerShow({
        id: this.customerId,
        params: {
          include: ['user', 'activeBooking.nonScheduledBookings', 'orders', 'orders.orderProducts', 'groups'],
        },
      })
    },
    loadBookings() {
      this.loading = true

      let params = {
        perPage: 50
      }
      if (this.group && this.group.customers.length > 0) {
        params.customerIds = this.group.customers.map((customer) => customer.id).join(',');
      } else {
        params.customerId = this.customer.id;
      }

      this.bookingIndex({
        params,
      }).then((response) => {
          this.bookingIds = response['ids']
      }).finally(() => {
        this.loading = false
      });
    },
    loadOrders() {
      this.loading = true

      let params = {
        perPage: 50
      }
      if (this.group && this.group.customers.length > 0) {
        params.customerIds = this.group.customers.map((customer) => customer.id).join(',');
      } else {
        params.customerId = this.customer.id;
      }

      this.orderIndex({
        params,
      }).then((response) => {
          this.orderIds = response['ids']
      }).finally(() => {
        this.loading = false
      })
    },
    loadServices() {
      this.loading = true

      this.serviceIndex({})
        .then((response) => {
          this.serviceIds = response.ids
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadActiveBooking() {
      this.loading = true

      return this.bookingShow({
        id: this.customerActiveBookingId,
        params: {
          include: ['nonScheduledBookings'],
        },
      })
        .then((response) => {
          this.activeBookingId = response[0]
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleBookingManage(booking) {
      this.$router.push('/admin/booking?edit=' + booking.id)
    },
    handleSchedulePendingBooking(nonScheduledBooking) {
      this.$router.push(`/admin/booking?create=true&nonScheduledBookingId=${nonScheduledBooking.id}&serviceId=${nonScheduledBooking.serviceId}&customerId=${this.customerId}`)
    },
    handleCreateNewBooking() {
      this.$router.push(`/admin/booking?create=true&customerId=${this.customerId}`)
    },
    associatedCustomerContextClick(customer) {
      this.$refs['associatedCustomerContext' + customer.id][0].toggle();
    },
    removeAssociatedCustomer(customer) {
      window.app.confirm({
        title: 'Remove Associated Customer',
        message: 'Are you sure you want to remove this associated customer?',
        onConfirm: () => {
          this.loading = true
          this.groupCustomerRemove({
            id: this.group.id,
            customer_id: customer.id
          }).then(() => {
            window.app.snackbar('Associated customer removed')
            this.loadDataAfterGroupUpdate();
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              })
            }
          }).finally(() => {
            this.loading = false
          })
        },
      })
    },
    async loadDataAfterGroupUpdate(){
      await this.loadCustomer()
      this.loadBookings()
      this.loadOrders()
    },
    fillForm() {
      if (this.customer) {
        this.form.fields = {
          ...this.customer,
        }
      }
    },
  },
}
</script>
