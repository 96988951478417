<template>
  <b-dropdown :ref="`statusRow${id}`" aria-role="list" position="is-bottom-left"
              class="has-text-left is-clickable rounded-8 honne-booking-status-update">

    <template #trigger="{ active }">

      <div @click.prevent.stop="handleStatusActionClick(id)">
        <b-button
          rounded
          :label="status"
          type="is-info"
          class="rounded-8 new-status-dropdown-button capitalize"
          :icon-right="active ? 'chevron-up' : 'chevron-down'"
        />
      </div>
    </template>

    <template v-for="statusOption in options">
      <b-dropdownItem aria-role="listitem" :disabled="statusOption.value === status" >
        <div @click.prevent.stop="$emit('update', statusOption)">
          {{ statusOption.label }}
        </div>
      </b-dropdownItem>
    </template>

  </b-dropdown>
</template>

<script>
export default {
  name: 'OrderStatusUpdate',
  props: {
    options: {
      required: true,
      type: Array,
    },
    id: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    }
  },
  methods: {
    handleStatusActionClick(id) {
      this.$refs['statusRow' + id].toggle();
    },
  }
}
</script>

<style lang="scss">
.honne-booking-status-update {
  .dropdown-content {
    padding: 8px;
    border-radius: 8px !important;
  }

  .dropdown-item, .dropdown .dropdown-menu .has-link a {
    padding: 0.375rem 0.5rem !important;
  }
}

</style>
